"use client";

import { Box, Flex, Stack, Textarea } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  AppNumberInput,
  AppSingleSelectDropdown,
  DatePicker,
  DurationInput,
  InputText,
  MultiSelectDropdown,
  SplitBtnInputValType,
  SplitButton,
  StaticText,
  TimeField,
} from "..";
import { useEffect, useState } from "react";
import { REGEX_PATTERN, splitDateTime } from "@/utils";
type T = string | number | null;
interface CoupledFieldWithExpr<T> {
  expOnValue: T | T[];
  expOnFormKey: string;
  expOnPlaceholder?: string;
  expOffValue: T | T[];
  expOffFormKey: string;
  expOffFormType?:
    | "singleSelect"
    | "multiSelect"
    | "inputText"
    | "inputNumber"
    | "textArea"
    | "staticText"
    | "dateTimePicker"
    | "durationInput";
  expOffPlaceholder?: string;
  labelName?: string;
  tooltipDesc?: string;
  isRequired?: boolean;
  optionsKeyName?: string;
  dropdownList?: T[];
  customClass?: string;
  numberInputSuffix?: string;
  numberInputPrefix?: string;
  durationModalTitle?: string;
  returnExprOnEmpty?: boolean;
  errors?: React.ReactNode;
  onExpOnChange: (value: T | T[]) => void;
  onExpOffChange: (value: T | T[]) => void;
  onExpOnBlur?: (event: React.FocusEvent) => void;
  onExpOffBlur?: (event: React.FocusEvent) => void;
}

export default function CoupledFieldWithExpr<T>(
  props: CoupledFieldWithExpr<T>
) {
  const {
    expOnValue,
    expOnFormKey,
    expOnPlaceholder = "Enter",
    expOffValue,
    expOffFormKey,
    expOffFormType = "inputText",
    expOffPlaceholder,
    labelName,
    tooltipDesc,
    isRequired = false,
    dropdownList = [],
    optionsKeyName,
    customClass,
    numberInputSuffix = "",
    numberInputPrefix = "",
    durationModalTitle = "",
    returnExprOnEmpty = false,
    errors,
    onExpOnChange,
    onExpOffChange,
    onExpOnBlur,
    onExpOffBlur,
  } = props;
  const [selectedExpType, setExpType] = useState<SplitBtnInputValType>(
    Boolean(expOffValue) ? false : Boolean(expOnValue)
  );
  const [cancelDate, setCancelDate] = useState<Date | null>(null);
  const [cancelTime, setCancelTime] = useState<string>("");

  // unset the other form value based on expr on/off
  useEffect(() => {
    if (selectedExpType && !returnExprOnEmpty) {
      onExpOffChange(null as T);
    } else if (!selectedExpType && returnExprOnEmpty) {
      onExpOnChange(null as T);
    }
  }, [selectedExpType]);

  useEffect(() => {
    if (expOffFormType === "dateTimePicker" && expOffValue) {
      const { date, time } = splitDateTime(expOffValue as string);
      setCancelDate(date ? new Date(date) : null);
      setCancelTime(time || "");
    }
  }, [expOffFormType, expOffValue]);

  const formatDate = (date: Date | null) => {
    if (!date) return null;
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
    return adjustedDate.toISOString().split("T")[0];
  };
  return (
    <Box className={`app-form-field-container ${customClass || ""}`}>
      <Flex mb={6} justifyContent={labelName ? "space-between" : "flex-end"}>
        {labelName ? (
          <AppFormLabel
            labelName={labelName}
            tooltipDesc={tooltipDesc}
            isRequired={isRequired}
            labelFor={`${expOffFormKey}_input`}
          />
        ) : (
          ""
        )}
        <Box className={"display-none"}>
          {labelName ? (
            <AppFormLabel
              labelName={labelName}
              tooltipDesc={tooltipDesc}
              isRequired={isRequired}
              labelFor={expOnFormKey}
            />
          ) : (
            ""
          )}
        </Box>

        <SplitButton
          name="comment"
          onSelect={setExpType}
          leftBtnName="Expr on"
          rightBtnName="Expr off"
          value={selectedExpType}
        />
      </Flex>
      <Box className={`${selectedExpType ? "" : "display-none"}`}>
        <InputText
          id={expOnFormKey}
          value={expOnValue as string}
          onChange={onExpOnChange as any}
          onBlur={onExpOnBlur}
          label={labelName}
          name={expOnFormKey}
          textarea={true}
          placeholder={expOnPlaceholder}
        />
      </Box>
      <Box className={`${selectedExpType === false ? "" : "display-none"}`}>
        {expOffFormType === "inputText" ? (
          <InputText
            id={expOffFormKey}
            value={expOffValue as string}
            onChange={onExpOffChange as any}
            onBlur={onExpOffBlur}
            label={labelName}
            name={expOffFormKey}
            placeholder={expOffPlaceholder || "Enter"}
          />
        ) : (
          ""
        )}
        {expOffFormType === "inputNumber" ? (
          <AppNumberInput
            value={expOffValue as any}
            placeholder={expOffPlaceholder || "Enter"}
            onChange={onExpOffChange as any}
            onBlur={onExpOffBlur}
            valuePrefix={numberInputPrefix}
            valueSuffix={numberInputSuffix}
          />
        ) : (
          ""
        )}
        {expOffFormType === "singleSelect" ? (
          <AppSingleSelectDropdown
            id={expOffFormKey}
            dropdownList={dropdownList}
            value={expOffValue as T}
            placeholder={expOffPlaceholder || "Select"}
            keyName={optionsKeyName || "label"}
            onChange={onExpOffChange}
            onBlur={onExpOffBlur}
          ></AppSingleSelectDropdown>
        ) : (
          ""
        )}
        {expOffFormType === "multiSelect" ? (
          <MultiSelectDropdown
            id={expOffFormKey}
            values={expOffValue as T[]}
            dropdownList={dropdownList}
            placeholder={expOffPlaceholder || "Select"}
            keyName={optionsKeyName || "label"}
            showCheckbox
            onChange={onExpOffChange}
            onBlur={onExpOffBlur}
          />
        ) : (
          ""
        )}
        {expOffFormType === "staticText" ? (
          <StaticText textValue={expOffValue as string} />
        ) : (
          ""
        )}
        {expOffFormType === "durationInput" ? (
          <DurationInput
            id={expOffFormKey}
            value={expOffValue as string}
            onChange={onExpOffChange as any}
            modalLinkName={"Enter code manually"}
            modalTitle={durationModalTitle ?? "Enter code"}
            modalLabelName={labelName ?? "Enter code"}
            modalLabelTooltipDesc={tooltipDesc}
          />
        ) : (
          ""
        )}
        {expOffFormType === "dateTimePicker" && (
          <Box display="flex" gap="2rem">
            <Stack flexBasis="50%">
              <DatePicker
                id="cancellation_date"
                value={cancelDate}
                onUpdate={(date) => {
                  setCancelDate(date);
                  onExpOffChange(`${formatDate(date)}T${cancelTime}` as any);
                }}
                placeholder="Select"
              />
            </Stack>
            <Stack flexBasis="50%">
              <TimeField
                value={cancelTime}
                onChange={(time) => {
                  setCancelTime(time);
                  onExpOffChange(`${formatDate(cancelDate)}T${time}` as any);
                }}
                ariaLabel="End time"
              />
            </Stack>
          </Box>
        )}
      </Box>
      {errors ?? null}
    </Box>
  );
}
