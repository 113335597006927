import axios from "axios";
import { deleteCookie, getCookie } from "cookies-next";
import QueryString from "qs";

const ADOBE_HOST = "https://learningmanager.adobe.com";
const BASE_URL = `${ADOBE_HOST}/primeapi/v2`;

export const getAuthHeader = () => {
  const access_token = getALMAccessToken();
  return {
    Authorization: `oauth ${access_token}`,
  };
};

export const getALMAccessToken = () => {
  return getCookie("adobe-token");
};

export const deleteALMAccessToken = () => {
  deleteCookie("adobe-token");
};

// This get will make a call to our console server which will leverage the learnToken API to get the users access_token
// The access_token is set in the adobe-token cookie on a successful response
export const requestALMAccessToken = async () => {
  await axios.get("/console/adobe-access-token");
};

// Checks if the access token stored in the adobe-token cookie is still valid.
// If it is not, then requestALMAccessToken should be called to get a new one
export const checkValidToken = async () => {
  const access_token = getALMAccessToken();
  return await axios.get(
    `${ADOBE_HOST}/oauth/token/check?access_token=${access_token}`
  );
};

export const getUserDetails = async () => {
  return await axios.get(`${BASE_URL}/user`, {
    headers: getAuthHeader(),
  });
};

export const getAllSessions = async (
  loFormat: string,
  itemsPerPage: number = 10
) => {
  return await axios.get(
    `${BASE_URL}/learningObjects?page[limit]=${itemsPerPage}&filter.loFormat=${loFormat}&include=enrollment.loResourceGrades.loResource.resources`,
    {
      headers: getAuthHeader(),
    }
  );
};
export const getLearnerObjectsByIds = async (ids: string, include?: string) => {
  const includeParam = include ? include : "enrollment.loResourceGrades";
  const res = await axios.get(
    `${BASE_URL}/learningObjects?filter.ignoreEnhancedLP=false&include=${includeParam}&ids=${ids}`,
    {
      headers: getAuthHeader(),
    }
  );
  return res;
};

export const getLearningObjects = async (
  filterString?: string,
  itemsPerPage: number = 10,
  sort?: string,
  pageCursor: string = ""
) => {
  const commonParams = QueryString.stringify({
    include: "enrollment.loResourceGrades",
    "page[limit]": itemsPerPage,
    ...(pageCursor ? { "&page[cursor]": pageCursor } : {}),
  });
  const filterQuery = QueryString.stringify({
    "filter.ignoreEnhancedLP": true,
    "filter.ignoreHigherOrderLOEnrollment": true,
  });
  // filter.bookmarks can't be used with other filter
  return await axios.get(
    `${BASE_URL}/learningObjects?${commonParams}&${filterString?.includes("bookmarks") ? filterString : `${filterQuery}&${filterString}`}`,
    {
      headers: getAuthHeader(),
    }
  );
};

export const getCompletedLearningObjects = async (
  itemsPerPage: number = 10
) => {
  return await axios.get(
    `${BASE_URL}/learningObjects?page[limit]=${itemsPerPage}&filter.loTypes=course&filter.learnerState=completed&sort=name&filter.ignoreEnhancedLP=false&filter.ignoreHigherOrderLOEnrollment=true`,
    {
      headers: getAuthHeader(),
    }
  );
};

export const getListData = async (url: string) => {
  return await axios.get(url, {
    headers: getAuthHeader(),
  });
};

export const getUserEnrollments = async (
  loTypes: "certification" | "course" | "learningProgram"
) => {
  const requestOptions = {
    method: "GET",
    headers: getAuthHeader(),
  };
  return await axios.get(
    `${BASE_URL}/enrollments?page[limit]=10&filter.loTypes=${loTypes}&sort=dateEnrolled&include=learningObject`,
    requestOptions
  );
};

export const getUserBadges = async (userId: string, include?: string) => {
  let url = "";
  if (include) {
    url = `&include=${include}`;
  }
  const requestOptions = {
    method: "GET",
    headers: getAuthHeader(),
  };
  return await axios.get(
    `${BASE_URL}/users/${userId}/userBadges?page[limit]=10&sort=dateAchieved${url}`,
    requestOptions
  );
};
// Define a type for the function's argument
type GetRecommendedCoursesParams = {
  includeBookmarks?: boolean;
  limit?: number;
  sort?: string;
};

export const getRecommendedCourses = async (
  params: GetRecommendedCoursesParams = {}
) => {
  const {
    includeBookmarks = false,
    limit = 10,
    sort = "-recommendationScore",
  } = params;

  try {
    const commonParams = QueryString.stringify({
      include: "enrollment.loResourceGrades",
      "page[limit]": limit,
      sort,
    });
    const filterQuery = QueryString.stringify({
      "filter.loTypes": "course",
      "filter.ignoreEnhancedLP": false,
      "filter.ignoreHigherOrderLOEnrollment": true,
    });
    const url = `https://learningmanager.adobe.com/primeapi/v2/learningObjects?${commonParams}${includeBookmarks ? "&filter.bookmarks=true" : filterQuery}`;

    const response = await axios.get(url, {
      headers: getAuthHeader(),
    });

    if (response?.data?.data) {
      return response.data.data;
    }

    return [];
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMessage =
        error.response?.status === 404
          ? "Recommended courses not found"
          : `API Error: ${error.message}`;
      throw new Error(errorMessage);
    } else {
      throw new Error(`Unexpected error: ${error}`);
    }
  }
};

export const getRecommendedCoursesUpdate = async (
  params: GetRecommendedCoursesParams = {}
) => {
  const { limit = 10, sort = "-recommendationScore" } = params;

  try {
    let url = `https://learningmanager.adobe.com/primeapi/v2/learningObjects?page[limit]=${limit}&filter.loTypes=course&sort=${sort}&filter.ignoreEnhancedLP=false`;

    const response = await axios.get(url, {
      headers: getAuthHeader(),
    });

    if (response?.data?.data) {
      return response.data.data;
    }

    return [];
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMessage =
        error.response?.status === 404
          ? "Recommended courses not found"
          : `API Error: ${error.message}`;
      throw new Error(errorMessage);
    } else {
      throw new Error(`Unexpected error: ${error}`);
    }
  }
};

export const getCourseDetails = async (courseId: string) => {
  try {
    const response = await axios.get(
      `https://learningmanager.adobe.com/primeapi/v2/learningObjects/${courseId}`,
      {
        headers: getAuthHeader(),
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMessage =
        error.response?.status === 404
          ? `Course details not found for ID: ${courseId}`
          : `API Error: ${error.message}`;
      throw new Error(errorMessage);
    } else {
      throw new Error(`Unexpected error: ${error}`);
    }
  }
};

export const getEnrollments = async () => {
  try {
    const response = await axios.get(
      "https://learningmanager.adobe.com/primeapi/v2/enrollments?page[limit]=10&filter.loTypes=course&includeHierarchicalEnrollments=false&sort=dateEnrolled",
      {
        headers: getAuthHeader(),
      }
    );

    return response.data.data; // Assuming you want to return the 'data' array
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMessage =
        error.response?.status === 404
          ? "Enrollments not found"
          : `API Error: ${error.message}`;
      throw new Error(errorMessage);
    } else {
      throw new Error(`Unexpected error: ${error}`);
    }
  }
};

export const getEnrollmentById = async (
  enrollmentId: string,
  includedParam?: string
) => {
  const param = includedParam ? `?include=${includedParam}` : "";
  try {
    const response = await axios.get(
      `https://learningmanager.adobe.com/primeapi/v2/enrollments/${enrollmentId}${param}`,
      {
        headers: getAuthHeader(),
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMessage =
        error.response?.status === 404
          ? `Enrollment with ID ${enrollmentId} not found`
          : `API Error: ${error.message}`;
      throw new Error(errorMessage);
    } else {
      throw new Error(`Unexpected error: ${error}`);
    }
  }
};

export const addBookmark = async (courseId: string) => {
  try {
    const response = await axios.post(
      `https://learningmanager.adobe.com/primeapi/v2/learningObjects/${courseId}/bookmark`,
      {},
      {
        headers: getAuthHeader(),
      }
    );

    if (response.status === 201) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const deleteBookmark = async (courseId: string) => {
  try {
    const response = await axios.delete(
      `https://learningmanager.adobe.com/primeapi/v2/learningObjects/${courseId}/bookmark`,
      {
        headers: getAuthHeader(),
      }
    );
    if (response.status === 201) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const getLearnerObjectInstancesByIds = async (
  loId: string,
  instanceIds: string,
  includeParam: "loResources" | "badge"
) => {
  return await axios.get(
    `${BASE_URL}/learningObjects/${loId}/instances/${instanceIds}?include=${includeParam}`,
    {
      headers: getAuthHeader(),
    }
  );
};
export const rateLearningProgram = async (enrollmentId: string, body: any) => {
  const res = await axios.patch(
    `${BASE_URL}/enrollments/${enrollmentId}/rate`,
    body,
    {
      headers: getAuthHeader(),
    }
  );
  return res;
};
export const getRateLearningProgram = async (courseId: string) => {
  const res = await axios.get(
    `${BASE_URL}/learningObjects/${courseId}?include=instances.enrollment`,
    {
      headers: getAuthHeader(),
    }
  );
  return res;
};

export const getPlayerUrl = async (
  type: string,
  courseId: string,
  moduleId: string
): Promise<string> => {
  const accessToken = getALMAccessToken();
  return `https://learningmanager.adobe.com/app/player?lo_id=${courseId}&access_token=${accessToken}&ccEnabled=true&module_id=${moduleId}`;
};

export const fetchPlayerData = async (
  type: string,
  courseId: string,
  moduleId: string
) => {
  try {
    const url = getPlayerUrl(type, courseId, moduleId);
    const response = await axios.get(await url, { headers: getAuthHeader() });
    return response.data; // Or handle the response as needed
  } catch (error) {
    // Handle errors
    if (axios.isAxiosError(error)) {
      const errorMessage = `API Error: ${error.message}`;
      throw new Error(errorMessage);
    } else {
      throw new Error(`Unexpected error: ${error}`);
    }
  }
};

export const getCertification = async (
  type: "available" | "in-progress" | "earned" | "upcoming"
) => {
  let url =
    "/learningObjects?filter.loTypes=certification&sort=name&filter.ignoreEnhancedLP=false";
  switch (type) {
    case "earned":
      url += "&filter.learnerState=completed&include=enrollment";
      break;
    case "in-progress":
      url += "&filter.learnerState=started&include=enrollment";
      break;
    case "available":
      url += "&filter.learnerState=enrolled";
      break;
    case "upcoming":
      url += "&filter.learnerState=notenrolled";
      break;
  }
  return await axios.get(`${BASE_URL}${url}`, {
    headers: getAuthHeader(),
  });
};

export const getBadges = async (userId: string, path?: string) => {
  const url = `/users/${userId}/userBadges?include=badge,model&sort=dateAchieved`;
  const API = path ? path : `${BASE_URL}${url}`;
  try {
    return await axios.get(API, {
      headers: getAuthHeader(),
    });
  } catch (error) {
    console.error("Failed to get badges", error);
    throw error;
  }
};

export const enrollUserInCourse = async (
  courseId: string,
  instanceId: string
) => {
  if (!courseId.trim() || !instanceId.trim()) {
    console.error("Enrollment failed: Missing Course ID or Instance ID.");
    throw new Error("Missing Course ID or Instance ID.");
  }

  const url = `${BASE_URL}/enrollments?loId=${encodeURIComponent(courseId)}&loInstanceId=${encodeURIComponent(instanceId)}`;

  try {
    const response = await axios.post(url, null, {
      headers: {
        "Content-Type": "application/vnd.api+json;charset=UTF-8",
        Accept: "application/vnd.api+json",
        Authorization: `oauth ${getALMAccessToken()}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error enrolling in course:", error);
    throw error;
  }
};

// Create a job to get job id to fetch the user badges
export const createPost = async (userBadgeId: string) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/jobs`,
      {
        data: {
          type: "job",
          attributes: {
            description: "creating a job",
            jobType: "generateUserBadge",
            payload: {
              userBadgeId,
              locale: "",
            },
          },
        },
      },
      {
        headers: getAuthHeader(),
      }
    );

    return response.data;
  } catch (error) {
    return false;
  }
};

export const getUserBadge = async (jobId: string) => {
  return await axios.get(`${BASE_URL}/jobs/${jobId}`, {
    headers: getAuthHeader(),
  });
};
