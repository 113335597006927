/**
 * Constants is a container module for static constants meant to be global
 * to the entire application.
 * @author Kyle Fox
 */

/* eslint-disable one-var */
// For long export lists, remove two steps to create a exported constant
export const ADMIN_OPERATIONS_REGEX =
    /^(admin-report-.*)|(admin-account-list|admin-course-list|admin-environment-list|admin-organization-list|admin-organization-read|admin-console-role-list|admin-core-build-list|access-management-access)$/,
  DTM_FORMAT = 'YYYY-MM-DD HH:mm:ss (Z)',
  DTM_NO_TZ_FORMAT = 'YYYY-MM-DD HH:mm:ss',
  DTMPICKER_FORMAT = 'YYYY-MM-DD HH:mm:ssZ',
  DTM_PRETTY_FORMAT = 'MMM Do, YYYY HH:mm:ssZ',
  DATE_FORMAT = 'YYYY-MM-DD',
  DATE_PRETTY_FORMAT = 'MMM Do, YYYY',
  ERROR = 'Error',
  ORGANIZATION_COOKIE = 'fxcOrg',
  SUCCESS = 'Success',
  TIME_FORMAT = 'HH:mm:ss (Z)',
  TIME_ONLY_FORMAT = 'HH:mm:ss',
  DATE_PLACEHOLDER = 'yyyy-mm-dd',
  /* Identifier that indicates current system time should be used in place
   * of this value.
   */
  SYSTEM_DATE_TIME = '-systemDateTime',
  TIME_PLACEHOLDER = '--:-- --',
  TIME_CLASSNAME = 'time',
  /*
   * Scorm Cloud Date Time format
   */
  SC_DATE_FORMAT = 'YYYYMMDDHHmmss',
  achPendingThresholdInMin = 15,
  reportPendingThresholdInMin = 15,
  reconBatchPendingThreshholdMin = 15,
  reconTrnPendingThreshholdMin = 15,
  architect = 'Architect',
  componentModelListKeys = ['componentName', 'version'],
  componentModelListConfig = {
    copySupported: true,
    createSupported: true,
    options: {
      columns: [
        {
          header: 'Name',
          property: 'componentName',
        },
        {
          header: 'Version',
          property: 'version',
        },
      ],
      initialSortBy: [
        { id: 'componentName', desc: true },
        { id: 'version', desc: false },
      ],
    },
    routeKeyProperties: componentModelListKeys,
  },
  coreFilterLimit = 'filter.limit',
  coreFilterOrderBy = 'filter.orderBy',
  coreFilterPage = 'filter.page',
  coreFilterQuery = 'filter.q',
  defaultSaveLabel = 'Save',
  defaultCancelLabel = 'Cancel',
  /**
   * Where environment ID is in local storage
   */
  envIdKey = 'environment-id',
  /**
   * Is it proj or env?
   */
  envTypeKey = 'environment-type',
  extProjTitle = 'Extensibility Projects',
  failedLoginAttempts = 'failed-login-attempts',
  fileDateHeader = 'File Date',
  fileDtmHeader = 'File DTM',
  fileTimeHeader = 'File Time',
  fontFamily = `'Roboto', sans-serif`,
  fundsAvlHoldPath = '/fundsAvlHold',
  fxtSelectWidget = 'fxtSelectWidget',
  groupingEnabledKey = 'org-grouping-enabled',
  millisecondRfc3339 = 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  monoFontFamily = 'Roboto Mono',
  monoTypeCell = 'mono-body mono-type',
  navCollapsedKey = 'sidebar-collapsed',
  noPathSeparators = `Can't contain path separators (/ or \\).`,
  operations = 'Operations',
  prod_cardTitle = 'Card Product Details',
  recentOrgsKey = 'recent-orgs',
  failedRequest = 'Unable to retrieve information',
  /**
   * Only a constant because FileFmt is the only standalone usage of FXTModelEdit (other than EditAndList).
   */
  routeParamName = 'modelId',
  routeParamRegex = '[A-Za-z0-9\\/._\\-+\\@\\:]*',
  bigIconSize = '1x',
  systemAccounts = 'System Accounts',
  validateAsciiStart = 'Must start with a lowercase ascii letter',
  schemaMinimumVersion =
    'Project must be using a core version of v1.12 or greater to make schema extensions',
  invalidSchemaChoicesFile = 'Invalid Choices File Format'
