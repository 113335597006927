import { Card, StyleProps } from "../../ChakraUiManager";
import "./CardContainer.scss";
import styleVars from "../../../styles/_export.module.scss";

const { appDefaultWhite } = styleVars;

type CardContainerProp = {
  children: React.ReactNode;
  styleProps?: StyleProps;
  customClass?: string;
};

export default function CardContainer({
  children,
  styleProps,
  customClass,
}: CardContainerProp) {
  return (
    <Card
      p={12}
      mt="5px"
      bg={appDefaultWhite}
      {...styleProps}
      className={`app-card-container ${customClass || ""}`}
    >
      {children}
    </Card>
  );
}
