"use client";

import CodeMirror from "@uiw/react-codemirror";
import {
  Flex,
  Stack,
  Box,
  ButtonGroup,
  Heading,
  Button,
  Image,
} from "@/components/ChakraUiManager";
import { Spacer } from "@chakra-ui/react";
import { dracula, draculaInit } from "@uiw/codemirror-theme-dracula";
import "./CodeContainer.scss";
import copy from "copy-to-clipboard";
import { downloadIcon, copyIcon } from "public/assets";
import { javascript } from "@codemirror/lang-javascript";
import { json } from "@codemirror/lang-json";
import { python } from "@codemirror/lang-python";
import { java } from "@codemirror/lang-java";
import { Extension } from "@codemirror/state";
import { GeneralizedTooltip } from "@/components/common";
import { tags as t } from '@lezer/highlight';


// Define a type for the language map
type LanguageMap = {
  [key: string]: Extension;
};

// Function to get the appropriate language extension
const getLanguageExtension = (language: string) => {
  const languageMap: LanguageMap = {
    javascript: javascript(),
    json: json(),
    python: python(),
    java: java(),
  };
  return languageMap[language?.toLowerCase()] || javascript(); // Default to javascript if unknown
};


const CodeContainer = ({ slice, sticky = false, withoutHeader = false }: any): JSX.Element => {
  const { code, title, codeHeading } = slice.data;
  let { language } = slice.data;
  const jsonDownloadHandler = (jsonData: string, fileName: string) => {
    const blob = new Blob([jsonData], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  //based on the codeheading update the language for endpoints, so we get color codes in the endpoints.
  if (codeHeading?.toLowerCase() === "endpoints") {
    language = "javascript";
  }

  return (
    <Box className="code-container" flex={1}>
      {/* Need this Box so that codemirror stack can be sticky  */}
      <Stack
        className={` ${sticky ? "cm-sticky" : ""}`}
        direction="column"
        gap={0}
      >
        {!withoutHeader && <Flex
          alignItems="center"
          gap="2"
          className="head"
        >
          <Box>
            <div className="heading">{codeHeading}</div>
          </Box>
          <Spacer />
          {codeHeading?.toLowerCase() !== "endpoints" && (
            <ButtonGroup minWidth="max-content">
              <GeneralizedTooltip placement="top" content="Language">
                <span className="language">{language}</span>
              </GeneralizedTooltip>
              <GeneralizedTooltip placement="top" content="Copy">
                <Button
                  onClick={() => {
                    copy(code);
                  }}
                  className="copy-btn"
                >
                  <Image src={copyIcon.src} alt="copy content" />
                </Button>
              </GeneralizedTooltip>
              <GeneralizedTooltip placement="top" content="Download">
                <Button
                  onClick={() => {
                    jsonDownloadHandler(
                      code,
                      (title ? title : codeHeading) + ".json"
                    );
                  }}
                  className="download-btn"
                >
                  <Image src={downloadIcon.src} alt="download content" />
                </Button>
              </GeneralizedTooltip>
            </ButtonGroup>
          )}
        </Flex>}
        <CodeMirror
          value={code}
          theme={draculaInit({
            styles: [
              { tag: t.bool, color: '#cbb2ff' },
              { tag: t.number, color: '#cbb2ff' },
              { tag: t.string, color: '#f1fa8c' },
            ]
          })}
          extensions={[getLanguageExtension(language)]}
          readOnly={true}
          basicSetup={{
            highlightActiveLineGutter: false,
            foldGutter: false,
            highlightActiveLine: false,
          }}
        />
      </Stack>
    </Box>
  );
};

export default CodeContainer;
