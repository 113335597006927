import { Flex } from "@chakra-ui/react";
import React from "react";
import Help from "../../../../public/assets/images/help.svg";
import CodeBlocks from "../../../../public/assets/images/code_blocks.svg";

import "./ResourceFlyout.scss";

export default function ResourceFlyout() {
  return (
    <div>
      <Flex flexDirection={"column"}>
       
        <>
          <img src={Help.src} alt="List" className="image-styling-flyout"></img>
          <p className="image-title-styling-flyout">Resource</p>
        </>

        <br />

        <>
          <img
            src={CodeBlocks.src}
            alt="List"
            className="image-styling-flyout"
          ></img>
          <p className="image-title-styling-flyout">APIs</p>
        </>
      </Flex>
    </div>
  );
}
