"use client";
import {
  Box,
  Button,
  Flex,
  SmallCloseIcon,
  Tag,
  TagLabel,
  TagRightIcon,
  Text,
} from "@/components/ChakraUiManager";
import "./FrequencyModule.scss";
import { AppNumberInput } from "@/components/common/form-fields/..";
import { Grid, GridItem } from "@/components/ChakraUiManager";
import AppFormLabel from "@/components/common/form-fields/form-label/AppFormLabel";
import AppSingleSelectDropdown from "@/components/common/form-fields/single-select-dropdown/AppSingleSelectDropdown";
import { useState, useRef, useEffect } from "react";
import AppModal from "@/components/common/app-modal/AppModal";
import InputText from "@/components/common/form-fields/input-text/InputText";
import {
  NAME_OF_CYCLE,
  NON_BUSINESS_DAY,
  NUMBER_OF_DAYS,
} from "@/components/data/data";
import { convertCodeToFrequency } from "@/utils";

export interface FrequencyModuleProps {
  intervalValue: string;
  cycleValue: string;
  dayOfMonth: string;
  notBusinessDay: string;
}

export const initialFrequencyValue: FrequencyModuleProps = {
  intervalValue: "",
  cycleValue: "",
  dayOfMonth: "",
  notBusinessDay: "",
};

interface FrequencyBoxProps {
  value: string;
  onChange: (value: string) => void;
  frequencyQuestionTitle?: string;
  frequencyQuestionDescription?: string;
  frequencyCodeText?: string;
  frequencyOutputContent?: string;
  frequencyOutputContentDesc?: string;
  monthDropdownLabel?: string;
  monthDropdownTooltip?: string;
  notBusinessDayLabel?: string;
  notBusinessDayTooltip?: string;
  onBlur?: (event: React.FocusEvent) => void;
  modalInputValue?: string;
  manuallyInputValueChange?: (value: string) => void;
}
const FrequencyModule = (props: FrequencyBoxProps) => {
  const {
    value,
    onChange,
    frequencyQuestionTitle,
    frequencyQuestionDescription,
    frequencyCodeText = "Enter code manually",
    frequencyOutputContent = "The rate adjustment will be occur every",
    frequencyOutputContentDesc = "If the date falls on a non-business day, such as a weekend or holiday, it will still be occur on the",
    monthDropdownLabel = "On what date should the frequency be implemented?",
    monthDropdownTooltip = "No Description As of Now",
    notBusinessDayLabel = "If the scheduled posting date is not a business day, when should the posting occur?",
    notBusinessDayTooltip = "No Description As of Now",
    onBlur,
  } = props;

  const convertFrequencyToCode = (value: FrequencyModuleProps) => {
    return `${value.intervalValue ?? ""}${value.cycleValue?.[0] ?? ""}${
      value.cycleValue === "Month" || value.cycleValue === "Year"
        ? value.dayOfMonth
        : ""
    }${value.notBusinessDay?.[0] ?? ""}`;
  };

  /*
    Assigning unique lables to input fields,
    (when multiple Frequency module exist on the same page)
  */
  const randomId = Math.random();

  const [manualCode, setManualCode] = useState("");
  const [tempCode, setTempCode] = useState("");
  const [frequencyValue, setFrequencyValue] = useState<FrequencyModuleProps>(
    value ? convertCodeToFrequency(value) : initialFrequencyValue
  );

  const currRef1 = useRef<any>();

  const onClickSubmit = () => {
    onChange(tempCode);
    setManualCode(tempCode);
    setTempCode("");
    // reset frequency object when user inputs code manually
    setFrequencyValue(initialFrequencyValue);
    // close modal after change
    currRef1?.current?.closeModal();
  };

  function onModalInputCancel() {
    // If users never saved the manual code, clear it
    setTempCode("");
  }

  function showModal() {
    setTempCode("");
    currRef1?.current?.openModal();
  }

  const onClear = () => {
    setManualCode("");
    setTempCode("");
    onChange("");
    setFrequencyValue(initialFrequencyValue);
  };

  const onEdit = () => {
    setTempCode(value);
    currRef1?.current?.openModal();
  };

  // We are creating handler to handle frequency module data
  const _handleChange =
    (key: keyof FrequencyModuleProps) => (value: string) => {
      if (key === "cycleValue" && value !== "Year" && value !== "Month") {
        setFrequencyValue((oldFreq) => {
          const newFreq = { ...oldFreq, [key]: value, dayOfMonth: "" };
          onChange(convertFrequencyToCode(newFreq));
          return newFreq;
        });
      } else {
        setFrequencyValue((oldFreq) => {
          const newFreq = { ...oldFreq, [key]: value };
          onChange(convertFrequencyToCode(newFreq));
          return newFreq;
        });
      }
    };

  const frequencyModuleRef = useRef<any>();

  // We are using Grid System from Chakara UI for Frequency Module
  // We are dividing Screen into 9:3 ratio and using Flex Box
  // from Chakara UI for arranging the components inside grid
  return (
    <Box className="frequency-module-container" ref={frequencyModuleRef}>
      <Grid templateColumns="2fr 1fr" gap={1}>
        <GridItem>
          <div>
            {frequencyQuestionTitle && (
              <AppFormLabel
                labelName={frequencyQuestionTitle}
                tooltipDesc={frequencyQuestionDescription}
              />
            )}
          </div>
          {!manualCode && (
            <Box mt={1}>
              <div className="dashed-left-border">
                <Box className="frequency-interval-cycle-container">
                  <Box display="flex" gap="3rem">
                    <Box>
                      <AppFormLabel labelName="Interval" />
                      <Box maxWidth="132px">
                        <AppNumberInput
                          placeholder="#"
                          onChange={_handleChange("intervalValue")}
                          value={
                            frequencyValue.intervalValue
                              ? frequencyValue.intervalValue
                              : ""
                          }
                        />
                      </Box>
                    </Box>
                    <Box>
                      <AppFormLabel
                        labelName="Cycle"
                        labelFor={`${randomId}_freq_cycle_input`}
                      />
                      <Box className="frequency-module-dropdown">
                        <AppSingleSelectDropdown
                          id={`${randomId}_freq_cycle`}
                          value={frequencyValue.cycleValue}
                          onChange={_handleChange("cycleValue")}
                          dropdownList={NAME_OF_CYCLE}
                          onBlur={onBlur}
                        />
                      </Box>
                    </Box>
                  </Box>
                  {(frequencyValue.cycleValue === "Month" ||
                    frequencyValue.cycleValue === "Year") && (
                    <Box className="frequency-day-of-month-box">
                      <Box mb="8px">
                        <AppFormLabel
                          labelName={monthDropdownLabel}
                          tooltipDesc={monthDropdownTooltip}
                          labelFor={`${randomId}_month_input`}
                        />
                        <Text>
                          only applicable if the cycle is monthly or yearly
                        </Text>
                      </Box>
                      <Box className="frequency-module-dropdown">
                        <AppSingleSelectDropdown
                          id={`${randomId}_month`}
                          value={frequencyValue.dayOfMonth}
                          onChange={_handleChange("dayOfMonth")}
                          dropdownList={NUMBER_OF_DAYS}
                          onBlur={onBlur}
                        />
                      </Box>
                    </Box>
                  )}
                  <Box
                    mt="32px"
                    display="flex"
                    flexDirection="column"
                    width="100%"
                  >
                    <AppFormLabel
                      labelName={notBusinessDayLabel}
                      tooltipDesc={notBusinessDayTooltip}
                      labelFor={`${randomId}_freq_business_day_input`}
                    />
                    <Box className="frquency-module-business-day-dropdown">
                      <AppSingleSelectDropdown
                        id={`${randomId}_freq_business_day`}
                        value={frequencyValue.notBusinessDay}
                        onChange={_handleChange("notBusinessDay")}
                        dropdownList={NON_BUSINESS_DAY}
                        onBlur={onBlur}
                      />
                    </Box>
                  </Box>
                  <Box mt="32px">
                    <Text className="frequency-or-text">OR</Text>
                  </Box>
                  <Box mt="16px">
                    <Text onClick={showModal} className="frequency-ecm-text">
                      {frequencyCodeText}
                    </Text>
                  </Box>
                </Box>
              </div>
            </Box>
          )}

          {manualCode && (
            <div className="frequency-module-value-container">
              <Box>
                <Flex className="frequency-module-selected-container">
                  <Tag className="app-tags app-tag-selected" variant="solid">
                    <TagLabel textTransform="capitalize">{value}</TagLabel>
                    <TagRightIcon
                      className="outer"
                      as={SmallCloseIcon}
                      onClick={onClear}
                    />
                  </Tag>
                </Flex>
                <Flex className="frequency-module-edit-btn-group">
                  <Button className="app-btn-link" onClick={onEdit}>
                    Edit
                  </Button>
                  <Button className="app-btn-link" onClick={onClear}>
                    Clear
                  </Button>
                </Flex>
              </Box>
            </div>
          )}
        </GridItem>

        {/* Right info panel */}
        <GridItem>
          {!manualCode && (
            <>
              <div className="card-container-description">
                <Text className="description-card-title">
                  What does this mean?
                </Text>
                <Text className="description-card-paragraph" fontSize="14px">
                  {frequencyOutputContent}
                  <span>{` [interval] [cycle] `}</span>
                  on the
                  <span>&nbsp;[day]&nbsp;</span>
                  of that month.
                </Text>
                <Text className="description-card-paragraph">
                  {frequencyOutputContentDesc} <span>{` [selection].`}</span>
                </Text>
              </div>
              {value && (
                <div className="card-container-code">
                  <Text>Code: {value}</Text>
                </div>
              )}
            </>
          )}
        </GridItem>
      </Grid>
      {/* When enter code manually cliked this modal will appear*/}
      <AppModal
        customClass="app-input-with-dropdown-modal"
        ref={currRef1}
        modalTitle="Enter frequency"
        primaryBtnProp={{
          name: "Save",
          btnClassName: "app-btn-inverse-secondary",
        }}
        primaryBtnSelect={onClickSubmit}
        secondaryBtnProp={{
          name: "Cancel",
          btnClassName: "app-btn-reg-secondary",
          style: { marginRight: 4 },
          enableCloseBtn: true,
        }}
        secondaryBtnSelect={onModalInputCancel}
        containerRef={frequencyModuleRef}
      >
        <AppFormLabel
          labelName={frequencyQuestionTitle}
          tooltipDesc={frequencyQuestionDescription}
        />
        <InputText value={tempCode} onChange={setTempCode} onBlur={onBlur} />
      </AppModal>
    </Box>
  );
};

export default FrequencyModule;
