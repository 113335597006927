import { ENV_ID_KEY, ENV_TYPE_KEY, EnvAllowConfigChanges } from "@/utils";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import localforage from "localforage";
import { RootState } from "..";
import { Environment } from "../types";

const initialEnvState: Environment = {
  endpoint: "",
  envId: null,
  envType: null,
  internal: false,
  isDisabled: false,
  showDiscoverKeyFeature: false,
  allowConfigChanges: null,
  isLoading: false,
};

// Load Env/Proj from browser storage
export const loadEnvOrProj = createAsyncThunk("env/loadEnvOrProj", async () => {
  try {
    const envId: number | null = await localforage.getItem(ENV_ID_KEY);
    const envType: "env" | "proj" | null =
      await localforage.getItem(ENV_TYPE_KEY);
    return {
      envId,
      envType,
    };
  } catch (err) {
    console.error(err);
  }
});

// Fetch env config
export const fetchEnvironmentConfig = createAsyncThunk(
  "env/fetchEnvConfig",
  async ({ envId, envType }: { envId: number; envType: "env" | "proj" }) => {
    try {
      const envConfig =
        envType === "env"
          ? await fetch(`/console/my/environment/${envId}`).then((res) =>
              res.json()
            )
          : null;
      return {
        envConfig,
      };
    } catch (err) {
      console.error(err);
    }
  }
);

// Create slice
const environmentSlice = createSlice({
  name: "enviroment",
  initialState: initialEnvState,
  reducers: {
    setEnvId: (state, action: PayloadAction<number | null>) => {
      state.envId = action.payload;
      localforage.setItem(ENV_ID_KEY, action.payload);
    },
    setEnvType: (state, action: PayloadAction<"env" | "proj" | null>) => {
      state.envType = action.payload;
      localforage.setItem(ENV_TYPE_KEY, action.payload);
    },
    setShowDiscoverKeyFeature: (state, action: PayloadAction<boolean>) => {
      state.showDiscoverKeyFeature = action.payload;
    },
    setAllowConfigChangeState: (
      state,
      action: PayloadAction<EnvAllowConfigChanges | null>
    ) => {
      state.allowConfigChanges = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch env config
      .addCase(fetchEnvironmentConfig.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchEnvironmentConfig.fulfilled, (state, action) => {
        const { envConfig } = action.payload || {};
        state.allowConfigChanges = envConfig?.allowConfigChanges ?? null;
        state.endpoint = envConfig?.endpoint ?? "";
        state.internal = envConfig?.internal ? envConfig?.internal : false
        state.isLoading = false;
      })
      // Load Env/Proj
      .addCase(loadEnvOrProj.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loadEnvOrProj.fulfilled, (state, action) => {
        const { envId, envType } = action.payload || {};
        state.envId = envId ?? null;
        state.envType = envType ?? null;
        state.isLoading = false;
      });
  },
});

// Environment selectors
export const getEnv = (state: RootState) => state.environment;
export const getEnvId = (state: RootState) => state.environment.envId;

export const getEnvType = (state: RootState) => state.environment.envType;

export const getEnvIsDisable = (state: RootState) =>
  state.environment.allowConfigChanges === EnvAllowConfigChanges.no;

export const getAllowConfigChangesState = (state: RootState) =>
  state.environment.allowConfigChanges;

export const getShowDiscoverKeyFeature = (state: RootState) =>
  state.environment.showDiscoverKeyFeature;

export { environmentSlice };
