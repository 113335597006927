import { AppSingleSelectDropdown } from "@/components/common";

type TimeSelectProps = {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  onBlur?: (event: React.FocusEvent) => void;
  disable?: boolean;
  id?: string;
};

const OPTIONS = Array.from({ length: 24 }, (_, i) => i).map((hour) =>
  hour < 10 ? `0${hour}:00:00` : `${hour}:00:00`
);

const TimeSelect = (props: TimeSelectProps) => {
  return (
    <AppSingleSelectDropdown
      customClass={props.className}
      value={props.value}
      onChange={props.onChange}
      dropdownList={OPTIONS}
      placeholder="09:00:00"
      onBlur={props.onBlur}
      disable={props.disable}
      id={props.id}
    />
  );
};

export default TimeSelect;
