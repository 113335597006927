import {
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
  Text,
} from "@/components/ChakraUiManager";
import "./DashboardHeader.scss";
import Link from "next/link";

type ProductTitleProp = {
  title: string;
  subTitle?: string;
};

const DashboardHeader = (props: ProductTitleProp) => {
  const { title, subTitle } = props;
  return (
    <Box className={"header-dashboard-element"}>
      <Box className="header-dashboard-back-ground-image">
        <Flex className={`top-dashboard-container`}>
          <Box
            marginLeft={13}
            marginBottom={11}
            mt={8}
            className={`top-dashboard-div`}
          >
            <Heading as={"h2"} className="top-dashboard-title">
              {title}
            </Heading>
            {subTitle && (
              <Text className="top-dashboard-sub-title">{subTitle}</Text>
            )}
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
export default DashboardHeader;
