"use client";
import { ApiClient, API_ROUTE_CONFIGURATION } from ".";
import { store } from "@/store";
import {
  GLConfigStatusType,
  GLStatusKey,
  ICConfigStatusKey,
  APP_STATUS,
  CRTConfigStatusType,
  ICConfigStatusType,
  CRTStatusKey,
  TCStatusKey,
} from "@/utils";
import { TcTemplatesResponse } from "@/components/transaction-code/models";
import { CONSOLE_ENDPOINTS } from "./urls";

interface CommonComponentAPIPayload {
  url: string;
  body: string;
}

interface CommonProductAPIPayload {
  productName: string;
  body: string;
}

interface WorkflowAPIPayload {
  stage?: string;
  status?: string;
  workflowType?: string;
}

interface ProdConfigWorkflowAPIPayload extends WorkflowAPIPayload {
  productName?: string;
}

interface ComponentListForComponentClassPayload {
  componentClass: string;
  pageLimit?: number;
  currPageNo?: number;
}

export type WorkflowResponse = {
  model: string;
  modelKey: string;
};

export interface ICWorkflowResponse extends WorkflowResponse {
  stage: ICConfigStatusKey;
  status: ICConfigStatusType;
}

export interface GLWorkflowResponse extends WorkflowResponse {
  stage: GLStatusKey;
  status: GLConfigStatusType;
}

export interface CrtWorkflowResponse extends WorkflowResponse {
  stage: CRTStatusKey;
  status: CRTConfigStatusType;
}
export type WorkflowResponseUnion =
  | ICWorkflowResponse
  | GLWorkflowResponse
  | TcWorkflowResponse
  | CrtWorkflowResponse;

export interface TcWorkflowResponse extends WorkflowResponse {
  stage: TCStatusKey;
  status: string;
}

export interface PCWorkflowResponse extends WorkflowResponse {
  stage: string;
  status: string;
}

export interface DashboardWorkflowResponse extends PCWorkflowResponse {}

export interface TcTemplateDeletePayload {
  code: string;
  channel: string;
  language: string;
  templateName: string;
  index: number;
}

export interface TcTemplateCommonPayload {
  code: string;
  index: number;
  body: TcTemplatesResponse;
}

const getEnvId = () => store.getState().environment.envId;
const getEnvType = () => store.getState().environment.envType;

// Sandbox fast-bank-dev envID
export const getCoreBaseEndpoint = () => `/core/${getEnvType()}/${getEnvId()}`;

export const getCoreModelEndpoint = () => `${getCoreBaseEndpoint()}/model`;

export const getCoreEndpoint = () => `${getCoreBaseEndpoint()}/core`;

export const getCorePermissionsEndpoint = () =>
  `/console/core/${getEnvType()}/${getEnvId()}`;

export const getConsoleOrganziationBaseURL = () => `/console/organization`;

export const getConsolePermissions = () => `/console/my`;

export const getConsoleAccountAdminEndpoint = () => `/console/account/admin`;

export const getConsoleAccountAdminEnvEndpoint = () =>
  `${getConsoleAccountAdminEndpoint()}/env/${getEnvId()}`;

export const getConsoleAccountsEndpoint = () => `/console/accounts`;

export const _throwError = async (res: Response) => {
  if (!res.ok) {
    try {
      const response = await res.json();
      throw response;
    } catch (e: any) {
      throw e?.stack?.includes("SyntaxError") ? res.statusText : e;
    }
  }
  return res?.json();
};

export function getWorkFlowBaseUrl(): string {
  return `/console/workflow/${getEnvType()}/${getEnvId()}/model`;
}

export const createComponent = async ({
  url,
  body,
}: CommonComponentAPIPayload) => {
  const api = new ApiClient({
    baseUrl: getCoreModelEndpoint(),
  });
  return api.post(url, { body });
};

export const updateComponent = async ({
  url,
  body,
}: CommonComponentAPIPayload) => {
  const api = new ApiClient({
    baseUrl: getCoreModelEndpoint(),
  });
  return api.patch(url, { body });
};

export const updateComponentWithPut = async ({
  url,
  body,
}: CommonComponentAPIPayload) => {
  const api = new ApiClient({
    baseUrl: getCoreModelEndpoint(),
  });
  return api.put(url, { body });
};

export const createTransCodeTemplate = async ({
  code,
  index,
  body,
}: TcTemplateCommonPayload) => {
  const api = new ApiClient({
    baseUrl: getCoreModelEndpoint(),
  });
  return api.post(
    `${API_ROUTE_CONFIGURATION.transCode}/${code}/entries/${index}${API_ROUTE_CONFIGURATION.templates}`,
    { body: JSON.stringify(body) }
  );
};

export const deleteTransCodeTemplate = async ({
  code,
  channel,
  language,
  templateName,
  index,
}: TcTemplateDeletePayload) => {
  const api = new ApiClient({
    baseUrl: getCoreModelEndpoint(),
  });
  return api.delete(
    `${API_ROUTE_CONFIGURATION.transCode}/${code}/entries/${index}${API_ROUTE_CONFIGURATION.templates}/${channel}/${templateName}/${language}`
  );
};

export const updateTransCodeTemplate = async ({
  code,
  index,
  body,
}: TcTemplateCommonPayload) => {
  const api = new ApiClient({
    baseUrl: getCoreModelEndpoint(),
  });
  return api.put(
    `${API_ROUTE_CONFIGURATION.transCode}/${code}/entries/${index}${API_ROUTE_CONFIGURATION.templates}/${body.channel}/${body.templateName}/${body.language}`,
    { body: JSON.stringify(body) }
  );
};
export const updateProduct = async ({
  productName,
  body,
}: CommonProductAPIPayload) => {
  const api = new ApiClient({
    baseUrl: getCoreModelEndpoint(),
  });
  return api.patch(`${API_ROUTE_CONFIGURATION.product}/${productName}`, {
    body,
  });
};

export const deleteWorkflowStage = async ({
  productName,
  stage,
}: ProdConfigWorkflowAPIPayload) => {
  const api = new ApiClient({
    baseUrl: getWorkFlowBaseUrl(),
  });
  return api.delete(
    `${API_ROUTE_CONFIGURATION.product}/${productName}/${stage}`
  );
};

// TC Workflow

export const getAllComponentsListForComponentClass = async ({
  componentClass,
  pageLimit = 10000,
  currPageNo = 1,
}: ComponentListForComponentClassPayload) => {
  const api = new ApiClient({
    baseUrl: getCoreModelEndpoint(),
  });
  return api.get(
    `${componentClass}?filter.limit=${pageLimit}&filter.page=${currPageNo}&filter.orderBy=-componentName,version`
  );
};

//Generic Workflow
export const createWorkflowGeneric = async ({
  model,
  key,
  stage,
  status,
}: {
  model: string;
  key: string | number;
  stage: string;
  status: string;
}) => {
  const api = new ApiClient({
    baseUrl: getWorkFlowBaseUrl(),
  });

  return api.post(`${model}/${key}/${stage}/${status}`);
};

export const updateWorkflowGeneric = async ({
  model,
  key,
  stage,
  status,
}: {
  model: string;
  key: string | number;
  stage: string;
  status: string;
}) => {
  const api = new ApiClient({
    baseUrl: getWorkFlowBaseUrl(),
  });

  return api.put(`${model}/${key}/${stage}/${status}`);
};

export const getWorkflowGeneric = async ({
  model,
  key,
  stage,
}: {
  model: string;
  key: string;
  stage?: string;
}) => {
  const api = new ApiClient({
    baseUrl: getWorkFlowBaseUrl(),
  });
  let url = stage ? `${model}/${key}/${stage}` : `${model}/${key}`;
  return api.get(url);
};

export const getAllWorkflowGeneric = async (model: string) => {
  const api = new ApiClient({
    baseUrl: getWorkFlowBaseUrl(),
  });

  return api.get(model);
};

export const deleteWorkflowGeneric = async ({
  model,
  key,
}: {
  model: string;
  key: string | number;
}) => {
  const api = new ApiClient({
    baseUrl: getWorkFlowBaseUrl(),
  });

  return api.delete(`${model}/${key}`);
};

export const getAllOrgs = async () => {
  const api = new ApiClient({
    baseUrl: CONSOLE_ENDPOINTS.myOrganizations,
  });
  return api.get();
};

export const getFavOrgs = async () => {
  const api = new ApiClient({
    baseUrl: CONSOLE_ENDPOINTS.base,
  });
  return api.get(`${API_ROUTE_CONFIGURATION.favOrgs}`);
};

export const addFavOrg = (orgId: number) => {
  const api = new ApiClient({
    baseUrl: CONSOLE_ENDPOINTS.base,
  });
  return api.post(`${API_ROUTE_CONFIGURATION.favOrgs}/${orgId}`);
};

export const removeFavOrg = (orgId: number) => {
  const api = new ApiClient({
    baseUrl: CONSOLE_ENDPOINTS.base,
  });
  return api.delete(`${API_ROUTE_CONFIGURATION.favOrgs}/${orgId}`);
};

export const getAllEnvironments = async () => {
  const api = new ApiClient({
    baseUrl: CONSOLE_ENDPOINTS.allMyEnvironments,
  });
  return api.get();
};

export const getAllProjects = async () => {
  const api = new ApiClient({
    baseUrl: CONSOLE_ENDPOINTS.projects,
  });
  return api.get();
};

export const getProjectById = (projId: number) => {
  const api = new ApiClient({
    baseUrl: CONSOLE_ENDPOINTS.base,
  });
  return api.get(`/project/${projId}`);
};
export const updateUserFastProfile = async (bodyData: any): Promise<any> => {
  const response = await fetch("/console/my/fastProfile", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...bodyData,
    }),
  });

  return response;
};

//access management

export const createUserAccount = async ({ body }: any): Promise<any> => {
  const api = new ApiClient({
    baseUrl: CONSOLE_ENDPOINTS.base,
  });
  return api.post(`${API_ROUTE_CONFIGURATION.amAccounts}`, {
    body: JSON.stringify(body),
  });
};

export const updateUserAccount = async ({
  body,
  id,
  orgId,
}: any): Promise<any> => {
  const isUserSameOrg = orgId === body.organization;
  const url = isUserSameOrg
    ? API_ROUTE_CONFIGURATION.amAccounts
    : API_ROUTE_CONFIGURATION.altAccount;
  const api = new ApiClient({
    baseUrl: CONSOLE_ENDPOINTS.base,
  });
  return api.put(`${url}/${id}`, {
    body: JSON.stringify(body),
  });
};

export const deleteUserAccount = async ({
  id,
  forceDelete,
}: any): Promise<any> => {
  const api = new ApiClient({
    baseUrl: CONSOLE_ENDPOINTS.base,
  });
  let url = `${API_ROUTE_CONFIGURATION.amAccounts}/${id}`;
  url = forceDelete ? `${url}?forceDeletion=true` : url;
  return api.delete(url);
};

export const deleteCustomRole = async ({ id }: any): Promise<any> => {
  const api = new ApiClient({
    baseUrl: CONSOLE_ENDPOINTS.base,
  });
  return api.delete(`${API_ROUTE_CONFIGURATION.customRole}/${id}`);
};

export const revokeUserAccount = async ({ id }: any): Promise<any> => {
  const api = new ApiClient({
    baseUrl: CONSOLE_ENDPOINTS.base,
  });
  return api.delete(`${API_ROUTE_CONFIGURATION.altAccount}/${id}`);
};

export const getEnvSpecificCoreRoles = async ({ id }: any): Promise<any> => {
  const api = new ApiClient({ baseUrl: getConsoleAccountAdminEndpoint() });
  return api.get(`/env/${id}/${API_ROUTE_CONFIGURATION.coreRoles}`);
};

export const resetUserPassword = async ({ id }: any) => {
  const api = new ApiClient({
    baseUrl: CONSOLE_ENDPOINTS.base,
  });
  return api.get(`${API_ROUTE_CONFIGURATION.resetPassword}/${id}`);
};

export const createEnvironmentRequest = async (config: any): Promise<any> => {
  const api = new ApiClient({
    baseUrl: CONSOLE_ENDPOINTS.base,
  });
  const {
    body,
    toastOptions: { toast, toastId, successMessage },
  } = config;
  return api
    .post(`${API_ROUTE_CONFIGURATION.envRequest}`, {
      body: JSON.stringify(body),
    })
    .then(async (res) => {
      if (!toast.isActive(toastId) && successMessage) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.error;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};
export const createFastRequest = async (config: any): Promise<any> => {
  const api = new ApiClient({
    baseUrl: CONSOLE_ENDPOINTS.base,
  });
  const {
    body,
    toastOptions: { toast, toastId, successMessage },
    type,
  } = config;
  return api
    .post(`${API_ROUTE_CONFIGURATION.fastRequest}/${type}`, {
      body: JSON.stringify(body),
    })
    .then(async (res) => {
      if (!toast.isActive(toastId) && successMessage) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.error;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};
