"use client";
import { useContext, useRef, useState } from "react";
import "./AppSingleSelectDropdown.scss";
import { Multiselect } from "multiselect-react-dropdown";
import { useSelector } from "react-redux";
import { getEntitlement, getEnvIsDisable } from "@/store";
import { Icon } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { IFX_ACCT_PRODUCT_TYPE, checkReadOnlyEntitlement, isConsolePages, useUserEntitlement } from "@/utils";
import { ProductConfigurationContextType, productConfigurationContext } from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
type Value<T> = T;
export interface SingleSelectDropdownProps<T> {
  value: Value<T>;
  onChange: (value: Value<T>) => void;
  keyName?: string;
  dropdownList: any[];
  placeholder?: string;
  customClass?: string;
  onBlur?: (event: React.FocusEvent) => void;
  disable?: any;
  id?: string;
  dropdownOptionDisabled?: boolean;
  hideClear?: boolean;
}

export default function AppSingleSelectDropdown<T>(
  props: SingleSelectDropdownProps<T>
) {
  const {
    value,
    onChange,
    placeholder = "Select",
    keyName,
    dropdownList,
    customClass,
    onBlur,
    disable,
    id,
    dropdownOptionDisabled,
    hideClear,
  } = props;

  const multiselectRef = useRef<any>();
  const envIsDisabled = useSelector(getEnvIsDisable);
  const entitlement = useSelector(getEntitlement);
  const isConsolePage = isConsolePages();
  const { productDetails } = useContext<ProductConfigurationContextType>(
    productConfigurationContext
  );
  const prodType = IFX_ACCT_PRODUCT_TYPE[productDetails?.ifxAcctType!];
  const checkEntitlement = checkReadOnlyEntitlement(entitlement, prodType);

  const onSelectItem = (_: any, selectedItem: any) => {
    // If option is an object return the value key, else return the option itself
    const newValue =
      typeof selectedItem === "string"
        ? selectedItem
        : selectedItem?.value ?? "";
    onChange(newValue);
  };
  const selectedValue =
    value != null && value !== "" && value !== -1
      ? keyName
        ? [dropdownList.find((item) => item.value === value) || {}]
        : [value]
      : null;
  function clearSelectedValue() {
    onChange("" as T);
  }
  const selectedValueDecorator = (option: any) => {
    return (
      <div className="option-value">
        <span>{option}</span>
        {option == selectedValue ? <Icon as={CheckIcon} mt={1.6} /> : ""}
      </div>
    );
  };
  return (
    <div className={`fx-single-select-container ${customClass || ""}`}>
      <div
        className={`fx-single-select ${
          dropdownOptionDisabled ? "fx-single-select-disabled" : ""
        }`}
        onBlur={onBlur}
      >
        <Multiselect
          customCloseIcon="close"
          selectedValues={selectedValue}
          className="fx-single-select-wrapper"
          hideSelectedList={false}
          isObject={keyName ? true : false}
          avoidHighlightFirstOption={true}
          displayValue={keyName}
          onSelect={onSelectItem}
          options={dropdownList}
          placeholder={placeholder}
          customArrow={true}
          closeOnSelect={true}
          singleSelect={true}
          ref={multiselectRef}
          disable={(envIsDisabled && isConsolePage) || disable || checkEntitlement}
          id={id}
          optionValueDecorator={selectedValueDecorator}
        />
      </div>
      {!hideClear && selectedValue && (
        <span className="clear" onClick={clearSelectedValue}>
          Clear
        </span>
      )}
    </div>
  );
}
