import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { ProductType, updateProductListStatus } from ".";
import { ApiClient } from "@/api-config/api-client";
import {
  getCoreModelEndpoint,
  getWorkFlowBaseUrl,
} from "@/api-config/api-service";
import { MATRIX_NAME, VALID_FROM_DTM } from "@/utils";
import { addDesiredComponent } from "./product-configuration/product-config-client-service";

async function fetchProductList(pageLimit = 10000, currPageNo = 1) {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  const workflowApi = new ApiClient({
    baseUrl: getWorkFlowBaseUrl(),
  });
  const coreBaseApi = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  const [product, workflowProduct, matricProdList] = await Promise.all([
    api.get(
      `${API_ROUTE_CONFIGURATION.product}?filter.limit=${pageLimit}&filter.page=${currPageNo}`
    ),
    workflowApi.get(`${API_ROUTE_CONFIGURATION.product}`),
    coreBaseApi.get(
      `${API_ROUTE_CONFIGURATION.matrix}/${MATRIX_NAME}/${VALID_FROM_DTM}`
    ),
  ]).then((data) => data);
  return product?.data
    ? updateProductListStatus(
        product.data,
        workflowProduct,
        matricProdList?.data
      )
    : [];
}

async function fetchProductGroup() {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  return api.get(`${API_ROUTE_CONFIGURATION.prodGroup}`);
}

async function updateProductInSummary(
  productName: string,
  payload: ProductType
) {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  return api.put(`${API_ROUTE_CONFIGURATION.product}/${productName}`, {
    body: JSON.stringify(payload),
  });
}

async function fetchExistingProduct(productName: string) {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  const workflowApi = new ApiClient({
    baseUrl: getWorkFlowBaseUrl(),
  });
  const coreBaseApi = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  const [product, workflowProduct] = await Promise.all([
    api.get(`${API_ROUTE_CONFIGURATION.product}/${productName}`),
    workflowApi.get(`${API_ROUTE_CONFIGURATION.product}/${productName}`),
    ,
  ]).then((data) => data);
  const { data } = product;

  const currProdMatrix = await coreBaseApi
    .get(
      `${API_ROUTE_CONFIGURATION.matrix}/${MATRIX_NAME}/${VALID_FROM_DTM}/${productName}`
    )
    .catch((err) => err);
  if (currProdMatrix?.errors) {
    await addDesiredComponent(
      data?.[0]?.name,
      data?.[0]?.components.map((item: any) => item.componentClass)
    );
    return updateProductListStatus(data, workflowProduct, []);
  }

  return updateProductListStatus(data, workflowProduct, currProdMatrix?.data);
}

async function createProduct(payload: ProductType) {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  return api.post(`${API_ROUTE_CONFIGURATION["product"]}`, {
    body: JSON.stringify(payload),
  });
}

export {
  fetchProductList,
  fetchProductGroup,
  updateProductInSummary,
  fetchExistingProduct,
  createProduct,
};
