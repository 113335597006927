import {
  LOAtrributes,
  LearningObjectContentItems,
  LearningObjectDetails,
} from "@/models/academy-models";
import { BadgesProps } from "../common/Badges/Badges";
import moment from "moment";
import { ROUTE_PATH } from "@/route-config/route-path";
import {
  badgePathDefault,
  certificationDefault,
  courseDefault,
} from "public/assets";
import { DATE_FORMAT } from "@/utils";

const LO_FORMATS = {
  activity: "Activity",
  selfPaced: "Self Paced",
};

const FAST_COURSE_CATEGORIZATION = {
  document: "Document",
  eLearning: "e-Learning",
  exam: "Exam",
  exercise: "Exercise",
};

export const COURSE_TAGS = {
  exam: "Exam",
  exercise: "Exercise",
};

export const LEARNING_OBJ_STATE = {
  published: "Published",
  expired: "Expired",
};

export const ALM_ENROLLMENT_STATE = {
  STARTED: "STARTED",
  ENROLLED: "ENROLLED",
  COMPLETED: "COMPLETED",
  REQUIRED: "REQUIRED",
  NOT_ENROLLED: "NOT_ENROLLED",
};
export const DEFAULT_LO_TYPES =
  "filter.loTypes=course,learningProgram,certification";

export const sortLearningPaths = (
  learningPaths: LearningObjectDetails[],
  order?: "asc" | "desc"
) => {
  learningPaths.sort(
    (
      learningPath1: LearningObjectDetails,
      learningPath2: LearningObjectDetails
    ) => {
      if (learningPath2.state === learningPath1.state) return 0;
      if (
        learningPath1.state === ALM_ENROLLMENT_STATE.COMPLETED ||
        (learningPath1.state === ALM_ENROLLMENT_STATE.STARTED &&
          learningPath2.state === ALM_ENROLLMENT_STATE.ENROLLED)
      )
        return 1;
      else return -1;
    }
  );
  if (order === "desc") learningPaths.reverse();
  return learningPaths;
};
export const sortLearningPaths1 = (
  learningPaths: LearningObjectDetails[],
  learningPathSortOrder: string[]
) => {
  if (learningPathSortOrder?.length)
    learningPaths.sort(function (a, b) {
      let index1 = learningPathSortOrder.indexOf(a.name);
      let index2 = learningPathSortOrder.indexOf(b.name);
      if (index1 < 0) return 1;
      if (index2 < 0) return -1;
      return index1 - index2;
    });
  return learningPaths;
};
export const getObjectById = (object: any[], id: string) => {
  return object?.filter((obj) => obj.id === id)[0];
};

export const constructContentItems = (
  response: any
): LearningObjectContentItems[] => {
  const contentItems: LearningObjectContentItems[] = [];
  response.included.forEach((item: any) => {
    const { id, attributes } = item;
    const { localizedMetadata, learnerAttemptInfo } = attributes;
    const progress = learnerAttemptInfo
      ? calculateProgress(learnerAttemptInfo)
      : 0;
    const resourceType = attributes.loResourceType;
    contentItems.push({
      moduleId: id,
      name: localizedMetadata[0].name,
      progress: progress,
      description: resourceType,
      resourseType: resourceType,
    });
  });

  return contentItems;
};

function calculateProgress(learnerAttemptInfo: any): number {
  if (learnerAttemptInfo.attemptsFinishedCount > 0) {
    return 100;
  } else {
    return 0;
  }
}

export const constructLearningObject = (data: any, isLearningObj: boolean) => {
  if (!data || !data.data) {
    return [];
  }

  let learningPaths: LearningObjectDetails[] = [];
  for (let i = 0; i < data.data.length; i++) {
    const currentLOData = data.data[i];

    if (
      (!isLearningObj && !currentLOData?.relationships?.learningObject) ||
      (isLearningObj && !currentLOData?.relationships?.enrollment)
    ) {
      continue;
    }

    let includedObj = null;
    if (data.included) {
      includedObj = isLearningObj
        ? getObjectById(
            data.included,
            currentLOData?.relationships?.enrollment?.data?.id
          )
        : getObjectById(
            data.included,
            currentLOData?.relationships?.learningObject?.data?.id
          );
    } else {
      continue;
    }

    let relatedGrades = data.included.filter(
      (includedItem: any) =>
        includedItem.type === "learningObjectResourceGrade" &&
        includedItem.id.includes(currentLOData.id)
    );

    let moduleDetails = relatedGrades.map((grade: any) => {
      return {
        moduleId: grade.relationships.loResource.data.id,
        duration: grade.attributes.duration,
        hasPassed: grade.attributes.hasPassed,
        progressPercent: grade.attributes.progressPercent,
        score: grade.attributes.score,
        dateCompleted: grade.attributes.dateCompleted || "",
        dateStarted: grade.attributes.dateStarted || "",
        dateSuccess: grade.attributes.dateSuccess || "",
      };
    });

    const enrollmentAttr: any = isLearningObj
      ? includedObj?.attributes
      : data.data[i].attributes;

    const learnerObjectAttr: LOAtrributes = isLearningObj
      ? data.data[i].attributes
      : includedObj?.attributes;

    let format = "";
    switch (learnerObjectAttr.loFormat) {
      case LO_FORMATS.activity:
        format = FAST_COURSE_CATEGORIZATION.document;
        break;
      case LO_FORMATS.selfPaced: {
        if (learnerObjectAttr.tags.includes(COURSE_TAGS.exam)) {
          format = FAST_COURSE_CATEGORIZATION.exam;
        } else if (learnerObjectAttr.tags.includes(COURSE_TAGS.exercise)) {
          format = FAST_COURSE_CATEGORIZATION.exercise;
        } else {
          format = FAST_COURSE_CATEGORIZATION.eLearning;
        }
      }
    }

    let learningPath: LearningObjectDetails = {
      name: learnerObjectAttr.localizedMetadata?.[0]?.name || "",
      description:
        learnerObjectAttr.localizedMetadata?.[0]?.description ||
        learnerObjectAttr.localizedMetadata?.[0]?.overview ||
        "",
      overview:
        learnerObjectAttr.localizedMetadata?.[0]?.richTextOverview ||
        learnerObjectAttr.localizedMetadata?.[0]?.overview ||
        "",
      duration: learnerObjectAttr.duration || 0,
      learnerObjectId: currentLOData.id || "",
      enrollmentId: isLearningObj ? includedObj?.id : currentLOData.id,
      state: enrollmentAttr.state || "NOT_ENROLLED",
      progressPercent: enrollmentAttr.progressPercent || 0,
      hasPassed: false,
      type: learnerObjectAttr.loType || "",
      format: format,
      rating: learnerObjectAttr.rating?.averageRating || 0,
      bannerUrl: learnerObjectAttr.bannerUrl || "",
      imageUrl: learnerObjectAttr.imageUrl || getLODefaultImage("course"),
      isBookmarked: learnerObjectAttr.isBookmarked || false,
      dateCreated: learnerObjectAttr.dateCreated || "",
      author: learnerObjectAttr.authorNames?.join(", ") || "",
      tags: learnerObjectAttr.tags?.join(", ") || "",
      instanceIds: currentLOData.relationships.instances?.data
        ? currentLOData.relationships.instances.data
            .map((instance: any) => instance.id)
            .join(",")
        : "",
      sections:
        learnerObjectAttr.sections?.map((section: any) => ({
          loIds: section.loIds || [],
          sectionId: section.sectionId || "",
          mandatory: section.mandatory || false,
          name: section.localizedMetadata?.[0]?.name || "",
        })) || [],
      completionDeadline: enrollmentAttr.completionDeadline || "",
      moduleDetails: moduleDetails || [],
    };

    learningPaths.push(learningPath);
  }

  return learningPaths;
};

export interface UserDetails {
  name: string;
  email: string;
}

export const constructUserDetails = (response: any): UserDetails | null => {
  if (
    !response ||
    !response.data ||
    !response.data.data ||
    !response.data.data.attributes
  ) {
    return null;
  }

  const userData = response.data.data;
  const userAttributes = userData.attributes;

  const userDetails: UserDetails = {
    name: userAttributes.name,
    email: userAttributes.email,
  };

  return userDetails;
};

export function getLODefaultImage(courseType: string) {
  switch (courseType) {
    case "learningProgram":
      return badgePathDefault.src;
    case "certification":
      return certificationDefault.src;
    default:
      return courseDefault.src;
  }
}

export function getBadgeByState({
  status,
  progressPercent,
  showState,
  dateCreated,
  label,
}: {
  status: string;
  progressPercent?: number | undefined;
  showState?: boolean;
  dateCreated?: string;
  label?: string;
}): BadgesProps {
  if (status === ALM_ENROLLMENT_STATE.COMPLETED) {
    return {
      variant: "subtle",
      colorScheme: "cyan",
      label: showState ? label ?? status : "COMPLETED",
      type: "success",
    };
  } else if (status === ALM_ENROLLMENT_STATE.STARTED) {
    return {
      variant: "subtle",
      colorScheme: "yellow",
      label: showState ? label ?? status : `${progressPercent}% COMPLETED`,
      type: "warning",
    };
  } else if (status === ALM_ENROLLMENT_STATE.REQUIRED) {
    return {
      variant: "subtle",
      colorScheme: "cyan",
      label: showState ? label ?? status : status,
      type: "primary",
    };
  } else {
    return {
      variant: "solid",
      colorScheme: "gray",
      label: showState
        ? label ?? status
        : dateCreated
          ? `ON ${dateCreated}`
          : status === ALM_ENROLLMENT_STATE.NOT_ENROLLED
            ? "NOT ENROLLED"
            : "NOT STARTED",
      type: "default",
    };
  }
}

export function formatDate(
  date: string | undefined,
  dateFormat = DATE_FORMAT.longDate
) {
  if (!date) return "";
  return moment(date).format(dateFormat);
}

export const SORTING_MULTI_SELECT_PROP = {
  dropdownList: [
    {
      label: "Alphabetical, A to Z",
      value: "name",
    },
    {
      label: "Alphabetical, Z to A",
      value: "-name",
    },
    {
      label: "Most recommended",
      value: "-recommendationScore",
    },
    {
      label: "Recently published",
      value: "-dateCreated",
    },
  ],
  dropdownName: "",
  keyName: "label",
  selectedOptionsTitle: "",
  placeholder: "Sort by",
  customClass: "catalog-sorting-select",
};

export const getDetailsPath = (type: string | undefined) => {
  switch (type) {
    case "learningProgram":
      return ROUTE_PATH.ACADEMY_CATALOG_BADGE_PATH;
    case "certification":
      return `${ROUTE_PATH.ACADEMY_BADGES_CERTIFICATION}/certification-path`;
    case "course":
      return ROUTE_PATH.ACADEMY_CATALOG_COURSE;
  }
  return "";
};

export const constructCertification = (
  data: any,
  includeEnrollment: boolean = false
) => {
  let learningPaths: LearningObjectDetails[] = [];
  for (let i = 0; i < data.data.length; i++) {
    const learnerObjectAttr: any = data.data[i].attributes;
    const enrollmentObjectAttr: any = includeEnrollment
      ? data.included.filter((item: any) =>
          item.id.includes(data.data[i].id)
        )[0].attributes
      : null;
    let learningPath: LearningObjectDetails = {
      name: learnerObjectAttr.localizedMetadata[0].name,
      description: learnerObjectAttr.localizedMetadata[0].description,
      overview: learnerObjectAttr.localizedMetadata[0].overview,
      duration: learnerObjectAttr.duration,
      learnerObjectId: data.data[i].id,
      enrollmentId: data.data[i].id,
      state: includeEnrollment
        ? enrollmentObjectAttr.state
        : ALM_ENROLLMENT_STATE.NOT_ENROLLED,
      progressPercent: 0,
      type: learnerObjectAttr.loType,
      format: learnerObjectAttr.loFormat,
      rating: learnerObjectAttr.rating?.averageRating,
      bannerUrl: learnerObjectAttr.bannerUrl,
      imageUrl:
        learnerObjectAttr.bannerUrl ??
        getLODefaultImage(learnerObjectAttr.loType),
      isBookmarked: learnerObjectAttr.isBookmarked,
      dateCreated: learnerObjectAttr.dateCreated,
      author: learnerObjectAttr.authorNames?.join(", "),

      dateCompleted: includeEnrollment
        ? enrollmentObjectAttr.dateCompleted
        : "",
      lastAccessDate: includeEnrollment
        ? enrollmentObjectAttr.lastAccessDate ??
          enrollmentObjectAttr.dateStarted
        : "",
    };

    // Show only earned or published certifications (filter out retired/draft)
    if (
      learnerObjectAttr.state === LEARNING_OBJ_STATE.published ||
      learningPath.dateCompleted
    ) {
      learningPaths.push(learningPath);
    }
  }
  return learningPaths;
};

export const constructCourses = (data: any) => {
  let courses: LearningObjectDetails[] = [];
  for (let i = 0; i < data.length; i++) {
    const learnerObjectAttr: any = data[i].attributes;
    if (
      data[i].type === "learningObject" &&
      learnerObjectAttr.state === LEARNING_OBJ_STATE.published
    ) {
      let course: LearningObjectDetails = {
        name: learnerObjectAttr.localizedMetadata[0].name,
        description: learnerObjectAttr.localizedMetadata[0].description,
        overview: learnerObjectAttr.localizedMetadata[0].overview,
        duration: learnerObjectAttr.duration,
        learnerObjectId: data[i].id,
        enrollmentId: data[i].relationships?.enrollment?.data?.id,
        state: ALM_ENROLLMENT_STATE.NOT_ENROLLED,
        progressPercent: 0,
        type: learnerObjectAttr.loType,
        format: learnerObjectAttr.loFormat,
        rating: learnerObjectAttr.rating?.averageRating,
        bannerUrl: learnerObjectAttr.imageUrl,
        isBookmarked: learnerObjectAttr.isBookmarked,
        dateCreated: learnerObjectAttr.dateCreated,
        author: learnerObjectAttr.authorNames?.join(", "),
        instanceIds: data[i].relationships?.instances?.data[0].id,
        tags: learnerObjectAttr.tags?.join(", ") || "",
      };

      courses.push(course);
    }
  }
  return courses;
};

export const getExpiryDate = (
  date: string | undefined,
  validity: string | undefined,
  format: string
) => {
  validity = validity ?? "0";
  const expiry = moment(date).add(validity?.replace("m", ""), "M");
  const expiryDt = moment(expiry).format(format);
  return expiryDt;
};

export const getDaysDiff = (dateCompleted?: string, validity?: string) => {
  const expiryDate = getExpiryDate(
    dateCompleted,
    validity,
    DATE_FORMAT.longDateWithCommma
  );
  const todayDate = moment().format(DATE_FORMAT.longDateWithCommma);
  const diff = moment(expiryDate.split(",")).diff(
    moment(todayDate.split(",")),
    "days",
    false
  );
  return diff <= 0 ? 0 : diff;
};
