"use client";

import {
  AppFormLabel,
  AppModal,
  AppSingleSelectDropdown,
  InputText,
  MultiSelectDropdown,
  RadioButtonGroup,
  AppNumberInput,
  FrequencyModule,
} from "../..";
import {
  AppRadioProps,
  RadioButtonGroupProps,
} from "../radio-button-group/RadioButtonGroup";
import "./CoupleRadioWithDropdown.scss";
import {
  Box,
  SmallCloseIcon,
  Tag,
  TagLabel,
  TagRightIcon,
} from "@/components/ChakraUiManager";
import { useRef, useState } from "react";
import { modalBtnProps } from "@/components/data/global-config";
import RjsfForm from "../../json-schema-form/RJSForm";
import { useRouter } from "next/navigation";
import { ROUTE_PATH } from "@/route-config/route-path";
import { Option, TC_ACTIONS } from "@/utils";

export type CoupleRadioOptionType = "PRIMARY" | "SECONDARY" | "" | "TERTIARY";
export type AddNewRadioOptionType = "modal" | "inputText" | "routeToTransCode";
export interface CoupleRadioWithDropdownProps<T>
  extends Omit<
    RadioButtonGroupProps<any>,
    "radioPropList" | "value" | "onChange"
  > {
  id?: string;
  [key: string]: any;
  name?: string;
  primaryRadioName: string;
  secondaryRadioName: string;
  tertiaryRadioName?: string;
  primaryOptionValue?: string | number;
  tertiaryOptionValue?: string | number;
  tertiaryFieldType?: "number" | "frequency";
  freqQuesTitle?: string;
  freqOutContentDesc?: string;
  freqOutContent?: string;
  notBusinessDayLabel?: string;
  monthDropdownLabel?: string;
  freqQuesTooltip?: string;
  monthDDTooltip?: string;
  businessDayTooltip?: string;
  placeHolderName?: string;
  dropdownList: Option<string>[] | string[];
  optionsKeyName?: string;
  isSingleSelect?: boolean;
  multiselectValue?: any[];
  multiSelectKeyName?: string;
  addNewFieldType?: AddNewRadioOptionType;
  addNewFieldQueryParam?: string;
  handleSecondaryModalOutput?: (value: T) => void;
  handlePrimaryOptionSelect: (value: T | T[]) => void;
  handleTertiaryOption?: (value: string) => void;
  radioOptionSelected?: CoupleRadioOptionType;
  onRadioValueChange?: (value: CoupleRadioOptionType) => void;
  onBlur?: (event: React.FocusEvent) => void;
  onAddNewSubmit?: (values: any) => Promise<void>;
  dropDownLabelName?: string;
  isDropdownLabelRequired?: boolean;
}

export default function CoupleRadioWithDropdown<T>(
  props: CoupleRadioWithDropdownProps<T>
) {
  const {
    id,
    primaryRadioName,
    secondaryRadioName,
    tertiaryRadioName,
    primaryOptionValue = "",
    tertiaryOptionValue = "",
    tertiaryFieldType,
    freqQuesTitle,
    freqOutContentDesc,
    freqOutContent,
    notBusinessDayLabel,
    monthDropdownLabel,
    freqQuesTooltip,
    monthDDTooltip,
    businessDayTooltip,
    isBoxedRadio = true,
    handlePrimaryOptionSelect,
    handleTertiaryOption,
    handleSecondaryModalOutput,
    dropdownName,
    placeHolderName,
    dropdownList = [],
    optionsKeyName,
    isSingleSelect = true,
    radioOptionSelected,
    onRadioValueChange,
    multiSelectKeyName,
    multiselectValue,
    onBlur,
    onAddNewSubmit,
    addNewFieldType = "modal",
    dropDownLabelName,
    isDropdownLabelRequired = false,
    addNewFieldQueryParam,
    ...rest
  } = props;
  const PRIMARY_RADIO_VALUE: CoupleRadioOptionType = "PRIMARY";
  const SECONDARY_RADIO_VALUE: CoupleRadioOptionType = "SECONDARY";
  const TERTIARY_RADIO_VALUE: CoupleRadioOptionType = "TERTIARY";
  const rjsfFormId = "rjsf-form";

  //states
  const modalRef = useRef<any>();
  const [radioValue, setRadioValue] = useState<string>(
    tertiaryOptionValue
      ? TERTIARY_RADIO_VALUE
      : primaryOptionValue || multiselectValue
        ? PRIMARY_RADIO_VALUE
        : ""
  );
  const router = useRouter();

  function handleRadioChange(value: string) {
    setRadioValue(value);
    switch (value) {
      case PRIMARY_RADIO_VALUE:
        handleTertiaryOption?.("");
        break;
      case SECONDARY_RADIO_VALUE:
        handleSecondaryRadioSelect(value);
        // Don't unset the value in multi select mode
        if (isSingleSelect) {
          handlePrimaryOptionSelect("" as T);
        }
        handleTertiaryOption?.("");
        break;
      case TERTIARY_RADIO_VALUE:
        handlePrimaryOptionSelect("" as T);
        break;
    }
    if (onRadioValueChange) {
      onRadioValueChange(value as CoupleRadioOptionType);
    }
  }

  function handleSecondaryRadioSelect(value: string) {
    if (addNewFieldType === "routeToTransCode" && addNewFieldQueryParam) {
      router.push(
        `${ROUTE_PATH.TC_FOUNDATIONAL_DETAILS}?${addNewFieldQueryParam}`
      );
    } else if (addNewFieldType !== "inputText" && modalRef.current) {
      modalRef.current.openModal();
    }
  }

  function constructRadioProp(): AppRadioProps<any>[] {
    const vals = [
      {
        label: primaryRadioName,
        value: PRIMARY_RADIO_VALUE,
      },
      {
        label: secondaryRadioName,
        value: SECONDARY_RADIO_VALUE,
      },
    ];
    if (tertiaryRadioName) {
      vals.unshift({
        label: tertiaryRadioName,
        value: TERTIARY_RADIO_VALUE,
      });
    }
    return vals;
  }

  const renderTertiaryField = () => {
    switch (tertiaryFieldType) {
      case "number":
        return (
          <Box className="dashed-left-border app-form-field-container">
            <AppNumberInput
              onChange={handleTertiaryOption!}
              placeholder="Enter"
              negativeValue={true}
              value={tertiaryOptionValue}
            />
          </Box>
        );
      case "frequency":
        return (
          <FrequencyModule
            value={tertiaryOptionValue as string}
            onChange={handleTertiaryOption!}
            frequencyQuestionTitle={freqQuesTitle}
            frequencyQuestionDescription={freqQuesTooltip}
            frequencyOutputContentDesc={freqOutContentDesc}
            frequencyOutputContent={freqOutContent}
            notBusinessDayLabel={notBusinessDayLabel}
            monthDropdownLabel={monthDropdownLabel}
            monthDropdownTooltip={monthDDTooltip}
            notBusinessDayTooltip={businessDayTooltip}
          />
        );
    }
  };

  return (
    <Box className="couple-radio-dropdown-container">
      <RadioButtonGroup
        {...rest}
        value={radioValue}
        spacing={"3rem"}
        radioPropList={constructRadioProp()}
        onChange={handleRadioChange}
        isBoxedRadio={isBoxedRadio}
        customClass={tertiaryRadioName ? "radio-group-tertiary" : ""}
      />

      {radioValue === TERTIARY_RADIO_VALUE ? (
        <Box mt={4}>{renderTertiaryField()}</Box>
      ) : null}

      {radioValue === PRIMARY_RADIO_VALUE ? (
        <>
          {isSingleSelect ? (
            <Box className="dashed-left-border couple-radio-label">
              {dropDownLabelName && (
                <AppFormLabel
                  labelName={dropDownLabelName}
                  isRequired={isDropdownLabelRequired}
                />
              )}
              <AppSingleSelectDropdown
                customClass="couple-radio-dropdown"
                dropdownList={dropdownList}
                value={primaryOptionValue as T}
                onChange={handlePrimaryOptionSelect}
                placeholder={placeHolderName || "Select"}
                keyName={optionsKeyName}
                onBlur={onBlur}
                id={id}
              ></AppSingleSelectDropdown>
            </Box>
          ) : (
            <Box className="dashed-left-border app-form-field-container couple-radio-label">
              {dropDownLabelName && (
                <AppFormLabel
                  labelName={dropDownLabelName}
                  isRequired={isDropdownLabelRequired}
                />
              )}
              <MultiSelectDropdown
                customClass="couple-radio-dropdown"
                dropdownList={dropdownList as any[]}
                showCheckbox={true}
                values={multiselectValue as any[]}
                onChange={handlePrimaryOptionSelect}
                keyName={optionsKeyName}
                placeholder={placeHolderName || "Select"}
                onBlur={onBlur}
                id={id}
              />
            </Box>
          )}
        </>
      ) : primaryOptionValue || multiselectValue?.length ? (
        <Box
          mt={8}
          className="dashed-left-border"
          display={"flex"}
          flexWrap="wrap"
          rowGap={4}
        >
          {primaryOptionValue ? (
            <Tag className="app-tags app-tag-selected" variant="solid">
              <TagLabel>{primaryOptionValue}</TagLabel>
              <TagRightIcon
                onClick={() => {
                  handlePrimaryOptionSelect(
                    multiselectValue?.length ? ([] as T) : ("" as T)
                  );
                  // unset radio state
                  setRadioValue("");
                }}
                as={SmallCloseIcon}
              />
            </Tag>
          ) : (
            multiselectValue?.map((val) => (
              <Tag
                key={val}
                className="app-tags app-tag-selected"
                variant="solid"
              >
                <TagLabel>{val}</TagLabel>
                <TagRightIcon
                  onClick={() => {
                    handlePrimaryOptionSelect(
                      multiselectValue?.length ? ([] as T) : ("" as T)
                    );
                    // unset radio state
                    setRadioValue("");
                  }}
                  as={SmallCloseIcon}
                />
              </Tag>
            ))
          )}
        </Box>
      ) : null}

      <AppModal
        customClass="app-modal-selector"
        ref={modalRef}
        modalTitle={secondaryRadioName}
        primaryBtnSelect={
          handleSecondaryModalOutput ? handleSecondaryModalOutput : null
        }
        primaryBtnProp={{
          ...modalBtnProps.primaryBtnProp,
          form: rjsfFormId,
        }}
        secondaryBtnProp={{
          name: "Cancel",
          btnClassName: "app-btn-reg-secondary",
        }}
        secondaryBtnSelect={() => setRadioValue("")}
      >
        <RjsfForm
          id={rjsfFormId}
          schema={props.schema}
          uiSchema={props.uiSchema}
          unRequire={props.unRequire}
          onSubmit={(data: any) => {
            onAddNewSubmit?.(data)
              .then(() => {
                modalRef.current?.closeModal();
              })
              .catch((err) => {
                console.error(err);
              });
          }}
          onError={(err) => {
            console.debug(err);
          }}
          noHtml5Validate={true}
        />
      </AppModal>
    </Box>
  );
}
