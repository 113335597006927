"use client";

import { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  SmallCloseIcon,
  Tag,
  TagLabel,
  TagRightIcon,
} from "../../ChakraUiManager";
import "./MultiSelectDropdown.scss";
import { Multiselect } from "multiselect-react-dropdown";
import { GLOBAL_TEXT } from "@/components/data/global-text";
import { AppFormLabel } from "..";
import Image from "next/image";
import { blackChewDownIcon } from "public/assets";
import { useSelector } from "react-redux";
import { getEntitlement, getEnvIsDisable } from "@/store";
import { IFX_ACCT_PRODUCT_TYPE, checkReadOnlyEntitlement, isConsolePages, useUserEntitlement } from "@/utils";
import { ProductConfigurationContextType, productConfigurationContext } from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";

export interface MultiSelectDropdownProps<T> {
  values: T[];
  onChange: (values: T[]) => void;
  onBlur?: (event: React.FocusEvent) => void;
  dropdownName?: string;
  tooltipDesc?: string;
  selectedOptionsTitle?: string;
  keyName?: string;
  dropdownList: T[];
  showCheckbox?: boolean;
  placeholder?: string;
  isRequired?: boolean;
  customClass?: string;
  showDashedLeftBorder?: Boolean;
  id?: string;
  keepSearchTerm?: boolean;
}

export default function MultiSelectDropdown<T>(
  props: MultiSelectDropdownProps<T>
) {
  const {
    values,
    onChange,
    placeholder = "Search",
    dropdownName,
    selectedOptionsTitle,
    keyName,
    dropdownList,
    showCheckbox = false,
    tooltipDesc = "",
    isRequired,
    customClass,
    showDashedLeftBorder = false,
    onBlur,
    id,
    keepSearchTerm = true,
  } = props;
  const [isDropdowListVisible, setIsDropdownListVisible] =
    useState<Boolean>(false);
  const multiselectRef = useRef<any>();
  const multiSelectContainerRef = useRef<any>(null);
  const { NO_LIST_MSG: errMsg } = GLOBAL_TEXT;
  const envIsDisabled = useSelector(getEnvIsDisable);
  const entitlement = useSelector(getEntitlement);
  const isConsolePage = isConsolePages();
  const { productDetails } = useContext<ProductConfigurationContextType>(
    productConfigurationContext
  );
  const prodType = IFX_ACCT_PRODUCT_TYPE[productDetails?.ifxAcctType!];
  const checkEntitlement = checkReadOnlyEntitlement(entitlement, prodType);

  const handleDocumentClick = (e: any) => {
    if (
      multiSelectContainerRef.current &&
      !multiSelectContainerRef.current.contains(e.target)
    ) {
      setIsDropdownListVisible(false);
    }
  };

  useEffect(() => {
    if (isDropdowListVisible) {
      document.addEventListener("click", handleDocumentClick);
    }
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [isDropdowListVisible]);

  function onRemoveItem(removedItem: any) {
    const listAfterRemoval = values.filter((item: any) => {
      return keyName ? item[keyName] !== removedItem : item !== removedItem;
    });
    onChange([...listAfterRemoval]);
    //remove item from dropdown list
    if (multiselectRef?.current) {
      multiselectRef?.current?.onRemoveSelectedItem(removedItem);
    }
  }

  function clearSelectedValues() {
    onChange([]);
  }
  const isOptionSelected = values?.length ? true : false;

  const handleToggleOptions = () => {
    const multiSelectContainer = document.getElementById(
      "multiselectContainerReact"
    );

    const optionListContainer = multiSelectContainer?.querySelector(
      ".optionListContainer"
    );

    isDropdowListVisible
      ? optionListContainer?.classList.replace("displayBlock", "displayNone")
      : optionListContainer?.classList.replace("displayNone", "displayBlock");

    setIsDropdownListVisible(!isDropdowListVisible);
  };
  return (
    <>
      <div
        className={`fx-multi-select-container ${customClass || ""}`}
        ref={multiSelectContainerRef}
      >
        <div className="fx-multi-select">
          {dropdownName ? (
            <AppFormLabel
              isRequired={isRequired}
              tooltipDesc={tooltipDesc}
              labelName={dropdownName}
            />
          ) : (
            ""
          )}
          <Box onBlur={onBlur}>
            <Multiselect
              customCloseIcon="close"
              className="fx-multiselect-wrapper"
              selectedValues={values?.length ? values : null}
              customArrow={
                <Button
                  onClick={handleToggleOptions}
                  className="chew-down-button"
                >
                  <Image src={blackChewDownIcon} alt="arrow-down" />
                </Button>
              }
              hideSelectedList={true}
              showArrow={true}
              avoidHighlightFirstOption={true}
              isObject={keyName ? true : false}
              displayValue={keyName}
              onSelect={onChange}
              onRemove={onChange}
              options={dropdownList}
              showCheckbox={showCheckbox}
              placeholder={placeholder}
              emptyRecordMsg={errMsg}
              closeOnSelect={true}
              ref={multiselectRef}
              id={id}
              keepSearchTerm={keepSearchTerm}
              disable={(envIsDisabled && isConsolePage) || checkEntitlement}
            />
          </Box>
        </div>
      </div>
      <div className="fx-selected-values-container">
        <div className={showDashedLeftBorder ? "dashed-left-border" : ""}>
          {values?.length ? (
            <>
              <Box
                className={`selected-options ${
                  isOptionSelected ? "option-selected" : ""
                }`}
              >
                <Flex className="tag-container">
                  {values?.length
                    ? values.map((option: any, index: any) => (
                        <>
                          <Tag
                            className="app-tags app-tag-selected"
                            variant="solid"
                            key={index}
                          >
                            <TagLabel>
                              {keyName ? option[keyName] : option}
                            </TagLabel>
                            <TagRightIcon
                              onClick={() => onRemoveItem(option)}
                              as={SmallCloseIcon}
                            />
                          </Tag>
                        </>
                      ))
                    : ""}
                </Flex>
                <span className="clear" onClick={clearSelectedValues}>
                  Clear
                </span>
              </Box>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
