"use client";
import React, { useState, useRef, useEffect, useContext } from "react";
import IconCalendar from "public/assets/images/calendar.svg";
import "react-calendar/dist/Calendar.css";
import "./Datepicker.scss";
import Calendar, { CalendarProps as ReactCalendarProps } from "react-calendar";
import Image from "next/image";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { getEntitlement, getEnvIsDisable } from "@/store";
import {
  DATE_FORMAT,
  IFX_ACCT_PRODUCT_TYPE,
  checkReadOnlyEntitlement,
  isConsolePages,
  isValidDate,
  useUserEntitlement,
} from "@/utils";
import moment from "moment";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";

interface CalendarProps {
  id?: string;
  value: Date | null;
  onUpdate: (date: Date) => void;
  focus?: boolean;
  error?: boolean;
  errmsg?: string;
  disabled?: boolean;
  small?: boolean;
  placeholder?: string;
  onBlur?: (event: React.FocusEvent) => void;
  ariaLabel?: string;
  place?:
    | "bottom-start"
    | "bottom"
    | "bottom-end"
    | "auto-start"
    | "auto"
    | "auto-end"
    | "top-start"
    | "top"
    | "top-end"
    | "left-start"
    | "left"
    | "left-end"
    | "right-start"
    | "right"
    | "right-end";
}
export default function DatePicker({
  id,
  value,
  onUpdate,
  focus,
  error,
  errmsg,
  disabled,
  small,
  placeholder,
  onBlur,
  ariaLabel,
  place,
}: CalendarProps) {
  const [selectedDate, setSelectedDate] = useState(
    value && isValidDate(value) ? value : null
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const envIsDisabled = useSelector(getEnvIsDisable);
  const entitlement = useSelector(getEntitlement);
  const isConsolePage = isConsolePages();
  const { productDetails } = useContext<ProductConfigurationContextType>(
    productConfigurationContext
  );
  const prodType = IFX_ACCT_PRODUCT_TYPE[productDetails?.ifxAcctType!];
  const checkEntitlement = checkReadOnlyEntitlement(entitlement, prodType);

  const handleUpdate = () => {
    if (selectedDate) {
      onUpdate(selectedDate);
    }
    onClose();
  };

  const handleCancel = () => {
    setSelectedDate(value);
    onClose();
  };

  const containerClassNames = [
    "input-wrapper",
    focus ? "date-picker-focus" : "",
    error ? "date-picker-error" : "",
    disabled ? "date-picker-disabled" : "",
    small ? "date-picker-small" : "",
  ].join(" ");

  const inputValue =
    value && isValidDate(value)
      ? moment(value).format(DATE_FORMAT.international)
      : "";
  return (
    <div ref={containerRef}>
      {error && <p className="error-text-calendar">{errmsg}</p>}
      <Popover
        isOpen={!envIsDisabled && !checkEntitlement && isOpen}
        onClose={onClose}
        placement={place ?? "auto-end"}
      >
        <PopoverTrigger>
          <div className={containerClassNames} onClick={onToggle}>
            <input
              id={id}
              className="datepicker-input"
              type="text"
              value={inputValue ?? ""}
              placeholder={placeholder ?? "Select"}
              readOnly
              onBlur={onBlur}
              aria-label={ariaLabel}
              disabled={
                (envIsDisabled && isConsolePage) || checkEntitlement || disabled
              }
            />
            <span
              className="calendar-symbol"
              onClick={(e) => e.preventDefault()}
            >
              <Image
                src={IconCalendar}
                alt="calendar icon"
                className="calendar-icon"
              />
            </span>
          </div>
        </PopoverTrigger>
        <Portal>
          <PopoverContent width="fit-content">
            <PopoverArrow />
            <PopoverBody padding={0}>
              <div className="calendar-overlay">
                <Calendar
                  //@ts-ignore
                  onChange={setSelectedDate}
                  value={selectedDate}
                />
                <div className="button-placing">
                  <button
                    onClick={handleCancel}
                    className="app-btn-reg-secondary-transparent"
                  >
                    Cancel
                  </button>

                  <button
                    onClick={handleUpdate}
                    className="app-btn-inverse-secondary"
                  >
                    Save
                  </button>
                </div>
              </div>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </div>
  );
}
