"use client";
import { configureStore } from "@reduxjs/toolkit";
import {
  environmentSlice,
  getEnvId,
  getEnvIsDisable,
  getEnvType,
  getShowDiscoverKeyFeature,
} from "./slices/environment";
import {
  orgSlice,
  getConsolePermissions,
  getCorePermissions,
  getOrgID,
  getOrganizations,
  getSelectedOrg,
  getEnvStatus,
} from "./slices/organization";
import {
  getProj,
  projectSlice
} from "./slices/project";
import { appSlice, getAppConfig, getAppPrismicClient } from "./slices/app";
import { entitlementSlice } from "./slices/entitlement";

export const DEFAULT_ALL_ORGANIZATION_ID = -1;

export const {
  setEnvId,
  setEnvType,
  setShowDiscoverKeyFeature,
  setAllowConfigChangeState,
} = environmentSlice.actions;

export const {
  setOrgId,
  setConsolePermissions,
  setCorePermissions,
  setOrganizations,
  setEnvStatus,
} = orgSlice.actions;

export const { setLoadingState } = appSlice.actions;
export const { setEntitlement } = entitlementSlice.actions;

// Create store
export const store = configureStore({
  reducer: {
    environment: environmentSlice.reducer,
    org: orgSlice.reducer,
    project: projectSlice.reducer,
    entitlement: entitlementSlice.reducer,
    app: appSlice.reducer,
  },
});

export {
  // env
  getEnvId,
  getEnvIsDisable,
  getEnvType,
  getShowDiscoverKeyFeature,

  // org
  getConsolePermissions,
  getCorePermissions,
  getOrgID,
  getOrganizations,
  getSelectedOrg,
  getEnvStatus,

  // proj
  getProj,

  // App
  getAppConfig,
  getAppPrismicClient,
};

export const getAppLoadingState = (state: RootState) =>
  state.app.isLoading || state.environment.isLoading || state.org.isLoading;
export const getEntitlement = (state: RootState) => state.entitlement;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
