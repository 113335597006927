"use client";
import React, { useEffect, useRef, useState } from "react";
import { CloseIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import "./ToolTip.scss";
import Image from "next/image";
import { kebabIcon } from "public/assets";

type TooltipProps = {
  content: string;
  placement: "top" | "right" | "bottom" | "left";
  IconDefault?: boolean;
  IconDeactivated?: boolean;
  close?: boolean;
  onToggle?: (val: boolean) => void;
  children?: React.ReactNode;
  onClick?: any;
  isViewSvgIcon?: boolean;
  isKebabIcon?: boolean;
  defaultOpen?: boolean;
};

export default function ToolTip({
  content,
  placement = "right",
  isViewSvgIcon = true,
  isKebabIcon,
  IconDefault,
  IconDeactivated,
  onToggle,
  close,
  defaultOpen,
}: TooltipProps) {
  const [isTooltipVisible, setTooltipVisible] = useState(Boolean(defaultOpen));

  const ref = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current?.contains(event?.target) && !defaultOpen) {
      setTooltipVisible(false);
    }
  };

  const toggleTooltip = () => {
    if (!IconDeactivated) {
      setTooltipVisible(!isTooltipVisible);
      if (onToggle) onToggle(!isTooltipVisible);
    }
  };
  if (close && isTooltipVisible) {
    setTooltipVisible(false);
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const containerClassNames = [
    IconDefault ? "icon-default" : "",
    IconDeactivated ? "icon-deactivated" : "",
    isTooltipVisible ? "blue-info-icon" : "",
  ].join(" ");

  return (
    <div className="tooltip-container" ref={ref}>
      {isViewSvgIcon ? (
        <div className="tooltip-icon" onClick={toggleTooltip}>
          <span>
            {
              <InfoOutlineIcon
                className={containerClassNames}
                viewBox="-5 -5 30 30"
              />
            }
          </span>
        </div>
      ) : isKebabIcon ? (
        <div onClick={toggleTooltip} className="icon-kebab">
          <Image src={kebabIcon} alt="kebab icon" />
        </div>
      ) : (
        <div className="circle" onClick={toggleTooltip}></div>
      )}

      {isTooltipVisible && (
        <div className={`tooltip-content ${placement}`}>
          <div className={`arrow ${placement}`}></div>
          <div className="content-layout">
            <div
              className="content-text"
              dangerouslySetInnerHTML={{ __html: content }}
            />
            <div className="close-icon" onClick={toggleTooltip}>
              <span>
                <CloseIcon boxSize={4} />
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
