"use client";

import { Box } from "@/components/ChakraUiManager";
import { CardContainer } from "..";
import Image from "next/image";
import "./ProductInfoCard.scss";
import { deleteIcon, editIcon } from "../../../../public/assets";

type ProductListProp = {
  name: string;
  value?: string;
};

export type ProductInfoCardProp = {
  title: string;
  productList: ProductListProp[];
};

function ProductInfoCard(prop: ProductInfoCardProp) {
  const { title, productList } = prop;

  return (
    <CardContainer customClass="product-info-card">
      <Box display="flex" justifyContent="space-between">
        <Box className="product-info-card-title">{title}</Box>
        <Box className="product-info-icons">
          <Image src={editIcon} alt="edit" />
          <Image src={deleteIcon} alt="edit" />
        </Box>
      </Box>
      <Box className="product-info-list">
        {productList?.length
          ? productList.map(({ name, value = "-" }: ProductListProp, productIndex) => (
              <Box className="product-info-item" key={`productIndex${productIndex}`}>
                <Box className="product-name" as="span">
                  {name}:
                </Box>
                <Box className="product-value" as="span">
                  {value}
                </Box>
              </Box>
            ))
          : ""}
      </Box>
    </CardContainer>
  );
}

export default ProductInfoCard;
