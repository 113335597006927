import { mediaImg, mediaImgLandScape } from "../../../public/assets";
import { AppModalProp } from "../common/app-modal/AppModal";
import { ContentCatalogueProp } from "../common/conent-catalogue/ContentCatalogue";
import { LearningInfoCardProps } from "../common/learning-info-card/LearningInfoCard";
import { ProductInfoCardProp } from "../common/product-info-card/ProductInfoCard";
import { RangeSliderProps } from "../common/range-slider/RangeSliderComponent";
import { ProductReviewDeployProps } from "../../interfaces/product-reiew-deploy";
import { DocumentSliderData } from "@/interfaces/document-center-documents";
import { AppRadioProps } from "../common/form-fields/radio-button-group/RadioButtonGroup";

const LEARNING_INFO_CARD_PROPS_1: LearningInfoCardProps = {
  title: "Finxact Academy",
  description:
    "Proin ut dui sed metus pharetra hend rerit vel non mi. Nulla ornare faucibus ex, non facilisis nisl. Maecenas aliquet mauris ut tempus Proin ut dui sed metus pharetra hend rerit vel non mi. ",
  tagList: ["React", "Node Js"],
  editorInfo: {
    name: "John Doe",
    date: "10/25/2022",
  },
  arrowLinkPath: "javascript:void(0)",
  mediaInfo: mediaImg,
};

const LEARNING_INFO_CARD_PROPS_2: LearningInfoCardProps = {
  title: "Finxact Academy",
  description:
    "Proin ut dui sed metus pharetra hend rerit vel non mi. Nulla ornare faucibus ex, non facilisis nisl. Maecenas aliquet mauris ut tempus Proin ut dui sed metus pharetra hend rerit vel non mi. ",
  tagList: ["React", "Node Js"],
  editorInfo: {
    name: "John Doe",
    date: "10/25/2022",
  },
  arrowLinkPath: "javascript:void(0)",
};

const LEARNING_INFO_CARD_PROPS_3: LearningInfoCardProps = {
  ...LEARNING_INFO_CARD_PROPS_2,
  mediaInfo: mediaImgLandScape,
};

const PRODUCT_INFO_CARD_PROPS_1: ProductInfoCardProp = {
  title: "Installment Component - Interest",
  productList: [
    {
      name: "Index Name",
      value: "LoanRate",
    },
    {
      name: "Review Frequency",
      value: "5 Years",
    },
    {
      name: "Post Frequency",
      value: "0MAE",
    },
    {
      name: "Fee Amount",
      value: "$20.00",
    },
    {
      name: "Amortization Duration",
      value: "12 Months",
    },
    {
      name: "Grace Period",
      value: "3 Days",
    },
    {
      name: "Minimum Payment",
      value: "$1.00",
    },
    {
      name: "Payment Duration",
      value: "12 Months",
    },
    {
      name: "Payment Frequency",
      value: "0MAE",
    },
    {
      name: "Tolerance Amount",
      value: "$0.00",
    },
  ],
};

const MODAL_DATA_1: AppModalProp = {
  modalTitle: "App Modal with Regular btn",
  description:
    "Proin ut dui sed metus pharetra hend rerit vel non mi. Nulla ornare faucibus ex, non facilisis nisl. Maecenas aliquet mauris ut tempus Proin ut dui sed metus pharetra hend rerit vel non mi. ",
  primaryBtnProp: {
    name: "Submit",
    btnClassName: "app-btn-reg-primary",
  },
  secondaryBtnProp: {
    name: "Cancel",
    btnClassName: "app-btn-reg-secondary",
    style: { marginRight: "1rem" },
    enableCloseBtn: true,
  },
  linkBtnProp: {
    name: "Link",
    btnClassName: "app-btn-link",
    style: { marginRight: "1rem" },
  },
};

const MODAL_DATA_2: AppModalProp = {
  ...MODAL_DATA_1,
  imageProp: mediaImgLandScape,
};

const MODAL_DATA_3: AppModalProp = {
  ...MODAL_DATA_1,
  imageProp: mediaImg,
  splitImage: true,
};

const CONTENT_CATALOGUE_1: ContentCatalogueProp = {
  title: "Based on your Save to Borrow",
  headingName:
    "Learn how to customize different feature and attributes of the Save to Borrow multi-position accoubt",
  headingLinkProp: {
    name: "View All",
  },
  catalogueList: [
    {
      title: "Built a spend amount",
      catalogueItemList: [
        {
          name: "Defining account Parameters",
          id: 12345,
        },
        {
          name: "Explore automatic zero balance closure",
        },
        {
          name: "Setting up virtual card",
        },
      ],
    },
    {
      title: "Built a Save account",
      catalogueItemList: [
        {
          name: "Processing Deposit",
          id: 98765,
        },
        {
          name: "Saving goals rewards deep dive",
        },
        {
          name: "Relationshipt Reward",
        },
      ],
    },
    {
      title: "Built a Borrow account",
      catalogueItemList: [
        {
          name: "Configure loan repayment",
          id: 55555,
        },
        {
          name: "Configure ballon loan",
        },
        {
          name: "Evaluating interest rate impact",
        },
      ],
    },
    {
      title: "Built a Borrow account",
      catalogueItemList: [
        {
          name: "Configure loan repayment",
          id: 55555,
        },
        {
          name: "Configure ballon loan",
        },
        {
          name: "Evaluating interest rate impact",
        },
      ],
    },
  ],
};

const MULTI_SELECT_PROP = {
  dropdownList: [
    "Option 1",
    "Option 2",
    "Option 3",
    "Option 4",
    "Option 5",
    "Option 6",
    "Option 7",
  ],
  dropdownName: "",
  selectedOptionsTitle: "Selected Option",
  placeholder: "Search Option",
};

const MULTI_SELECT_PROP_LEDGERS = {
  dropdownList: ["Option 1", "Option 2", "Option 3", "Option 4", "Option 5"],
  dropdownName: "Available Ledgers",
  selectedOptionsTitle: "Selected Option",
  placeholder: "Search Option",
};

const MULTI_SELECT_PROP_WITH_ARR_OBJ = {
  dropdownList: [
    {
      name: "Alabama",
    },
    {
      name: "Alaska",
    },
    {
      name: "Arkansas",
    },
    {
      name: "California",
    },
    {
      name: "New Jersey",
    },
    {
      name: "New York",
    },
  ],
  dropdownName: "Multi Select Dropdown with object",
  selectedOptionsTitle: "Selected Option",
  placeholder: "Search Option",
  keyName: "name",
};

const RANGE_SLIDER_PROPS: RangeSliderProps = {
  componentLabel: "Loan to Value Ratio (%)",
  minRangeLabel: "Min",
  maxRangeLabel: "Max",
  defaultMinRange: 0.2,
  defaultMaxRange: 0.8,
  minRangeValue: 0.0,
  maxRangeValue: 1.0,
  minRangeDisplayValue: 0,
  maxRangeDisplayValue: 100,
  stepRange: 0.01,
};

const PRODUCT_REVIEW: ProductReviewDeployProps = {
  productSummary: {
    title: "Product name lorem ipsum dolor",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip",
    prodType: "Lorem ipsum dolor",
    prodSubtype: "Lorem ipsum dolor",
    completed: "6/6",
    incomplete: "None",
    issues: "0",
    components: [
      {
        componentTitle: "Product attributes",
        componentDesc: "Description lorem ipsum dolor sit amet",
        success: true,
        features: [
          {
            featureTitle: "Product basics",
            success: true,
            attributes: [
              {
                label: "Product name",
                value: "lorem ipsum",
              },
              {
                label: "Product description",
                value: "lorem ipsum",
              },
            ],
          },
          {
            featureTitle: "Account details",
            success: true,
            attributes: [
              {
                label: "IFX account type",
                value: "Savings Deposit Account",
              },
              {
                label: "Position class",
                value: "Bank deposit account position",
              },
              {
                label: "Currency code",
                value: "USD",
              },
            ],
          },
          {
            featureTitle: "General ledger parameters",
            success: true,
            attributes: [
              {
                label: "GL category",
                value: "Liability",
              },
              {
                label: "General ledger set code",
                value:
                  "BusSavgreat100k - Business Savings > 100k GL Set Code; BusSavless100k - Business Savings < 100k GL Set Code",
              },
              {
                label: "GL set code matrix name",
                value: "lorem_ipsum",
              },
            ],
          },
          {
            featureTitle: "Regulatory adherence & limitations",
            success: true,
            attributes: [
              {
                label: "Product name",
                value: "lorem ipsum",
              },
              {
                label: "Product description",
                value: "lorem ipsum",
              },
            ],
          },
          {
            featureTitle: "Interim updates",
            success: true,
            attributes: [
              {
                label: "Product name",
                value: "lorem ipsum",
              },
              {
                label: "Product description",
                value: "lorem ipsum",
              },
            ],
          },
        ],
      },
      {
        componentTitle: "Component Selection",
        componentDesc: "Description lorem ipsum dolor sit amet",
        success: false,
        errorInfo: "1 error: General ledger parameters",
        features: [
          {
            featureTitle: "Product basics",
            success: true,
            attributes: [
              {
                label: "Product name",
                value: "lorem ipsum",
              },
              {
                label: "Product description",
                value: "lorem ipsum",
              },
            ],
          },
          {
            featureTitle: "Account details",
            success: true,
            attributes: [
              {
                label: "IFX account type",
                value: "Savings Deposit Account",
              },
              {
                label: "Position class",
                value: "Bank deposit account position",
              },
              {
                label: "Currency code",
                value: "USD",
              },
            ],
          },
          {
            featureTitle: "General ledger parameters",
            success: false,
            errorInfo: "1 error: incorrect syntax",
            attributes: [
              {
                label: "GL category",
                value: "Liability",
              },
              {
                label: "General ledger set code",
                value:
                  "BusSavgreat100k - Business Savings > 100k GL Set Code; BusSavless100k - Business Savings < 100k GL Set Code",
              },
              {
                label: "GL set code matrix name",
                value: "lorem_ipsum",
              },
            ],
          },
          {
            featureTitle: "Regulatory adherence & limitations",
            success: true,
            attributes: [
              {
                label: "Product name",
                value: "lorem ipsum",
              },
              {
                label: "Product description",
                value: "lorem ipsum",
              },
            ],
          },
          {
            featureTitle: "Interim updates",
            success: true,
            attributes: [
              {
                label: "Product name",
                value: "lorem ipsum",
              },
              {
                label: "Product description",
                value: "lorem ipsum",
              },
            ],
          },
        ],
      },
    ],
  },
};

const DOCUMENT_SLIDER: DocumentSliderData[] = [
  {
    title: "Finxact Academy",
    description:
      "Proin ut dui sed metus pharetra hend rerit vel non mi. Nulla ornare faucibus ex, non facilisis nisl. Maecenas aliquet mauris ut tempus Proin ut dui sed metus pharetra hend rerit vel non mi. ",
    tagList: ["React", "Node Js"],
    editorInfo: {
      name: "John Doe",
      date: "10/25/2022",
    },
    arrowLinkPath: "javascript:void(0)",
    mediaInfo: mediaImg,
  },
  {
    title: "Finxact Academy",
    description:
      "Proin ut dui sed metus pharetra hend rerit vel non mi. Nulla ornare faucibus ex, non facilisis nisl. Maecenas aliquet mauris ut tempus Proin ut dui sed metus pharetra hend rerit vel non mi. ",
    tagList: ["React", "Node Js"],
    editorInfo: {
      name: "John Doe",
      date: "10/25/2022",
    },
    arrowLinkPath: "javascript:void(0)",
    mediaInfo: mediaImg,
  },
  {
    title: "Finxact Academy",
    description:
      "Proin ut dui sed metus pharetra hend rerit vel non mi. Nulla ornare faucibus ex, non facilisis nisl. Maecenas aliquet mauris ut tempus Proin ut dui sed metus pharetra hend rerit vel non mi. ",
    tagList: ["React", "Node Js"],
    editorInfo: {
      name: "John Doe",
      date: "10/25/2022",
    },
    arrowLinkPath: "javascript:void(0)",
    mediaInfo: mediaImg,
  },
  {
    title: "Finxact Academy",
    description:
      "Proin ut dui sed metus pharetra hend rerit vel non mi. Nulla ornare faucibus ex, non facilisis nisl. Maecenas aliquet mauris ut tempus Proin ut dui sed metus pharetra hend rerit vel non mi. ",
    tagList: ["React", "Node Js"],
    editorInfo: {
      name: "John Doe",
      date: "10/25/2022",
    },
    arrowLinkPath: "javascript:void(0)",
    mediaInfo: mediaImg,
  },
  {
    title: "Finxact Academy",
    description:
      "Proin ut dui sed metus pharetra hend rerit vel non mi. Nulla ornare faucibus ex, non facilisis nisl. Maecenas aliquet mauris ut tempus Proin ut dui sed metus pharetra hend rerit vel non mi. ",
    tagList: ["React", "Node Js"],
    editorInfo: {
      name: "John Doe",
      date: "10/25/2022",
    },
    arrowLinkPath: "javascript:void(0)",
    mediaInfo: mediaImg,
  },
];

const RADIO_DATA_LIST: AppRadioProps<any>[] = [
  {
    label: "label 1",
    value: "1",
  },
  {
    label: "label 2",
    value: "2",
  },
  {
    label: "label 3",
    value: "3",
  },
  {
    label: "label 4",
    value: "4",
  },
  {
    label: "label 5",
    value: "5",
    disabled: true,
  },
];

const RADIO_FEE_TYPE_OPTION: AppRadioProps<any>[] = [
  {
    label: "Unknown",
    value: "1",
  },
  {
    label: "Late charge",
    value: "2",
  },
  {
    label: "Penalty Payment",
    value: "3",
  },
];
const CURRENCY_DATA = [
  {
    value: "AUD $",
    label: "Australia Dollar",
  },
  {
    value: "BRL R$",
    label: "Brazil Real",
  },
  {
    value: "CAD $",
    label: "Canada Dollar",
  },
  {
    value: "CNY ¥",
    label: "China Yuan Renminbi",
  },
  {
    value: "ISK kr",
    label: "Iceland Krona",
  },
  {
    value: "USD $",
    label: "United States Dollar",
  },
  {
    value: "EUR €",
    label: "Europeon Union",
  },
];

const REMOVE_USER_RADIO_LIST: AppRadioProps<any>[] = [
  {
    label: "Remove from this console instance",
    value: "1",
  },
  {
    label: "Remove from all console instances",
    value: "2",
  },
];
const NAME_OF_CYCLE: string[] = ["Business day", "Day", "Month", "Year"];
const NUMBER_OF_DAYS: string[] = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "E",
];
const NON_BUSINESS_DAY: string[] = [
  "Actual date",
  "Next business date",
  "Previous business date",
];

const POSTING_ROUND_OPTION: AppRadioProps<any>[] = [
  {
    label: "Round interest up",
    value: "1",
  },
  {
    label: "Truncate",
    value: "2",
  },
  {
    label: "Round to nearest down (0.00500 rounds down)",
    value: "3",
  },
  {
    label: "Bankers round ",
    value: "4",
  },
  {
    label: "Rounds to nearest up (0.00500 rounds up) ",
    value: "5",
  },
];
const DISBURSMENT_OPTION: AppRadioProps<any>[] = [
  {
    label: "Not Applicable",
    value: "1",
  },
  {
    label: "Capitalize",
    value: "2",
  },
  {
    label: "Transfer",
    value: "3",
  },
];

const GUIDED_TOOLTIP_STEPS = [
  {
    content:
      "Welcome to your Finxact Core Banking! Let’s begin on the Dashboard. As a client,  you’ll gain an insightful summary of your Finxact Core Banking metrics, along with an intuitive configuration journey that will allow you to quickly begin configuring your banking experience.",
    placement: "center",
    target: "body",
    title: "Title Input",
  },
  {
    content:
      "Welcome to your Finxact Core Banking! Let’s begin on the Dashboard. As a client,  you’ll gain an insightful summary of your Finxact Core Banking metrics, along with an intuitive configuration journey that will allow you to quickly begin configuring your banking experience.",
    placement: "top",
    target: ".app-form-field-container",
    title: "Title Input",
  },
  {
    content:
      "Welcome to your Finxact Core Banking! Let’s begin on the Dashboard. As a client,  you’ll gain an insightful summary of your Finxact Core Banking metrics, along with an intuitive configuration journey that will allow you to quickly begin configuring your banking experience.",
    placement: "bottom",
    target: ".fds",
    title: "Title Input",
  },
];

const TEST_COMPONENT_GUIDED_TOOLTIP_STEPS = [
  {
    content:
      "Welcome to your Finxact Core Banking! Let’s begin on the Dashboard. As a client,  you’ll gain an insightful summary of your Finxact Core Banking metrics, along with an intuitive configuration journey that will allow you to quickly begin configuring your banking experience.",
    title: "Right Tooltip",
    placement: "right",
    target: "#right",
  },
  {
    content:
      "Welcome to your Finxact Core Banking! Let’s begin on the Dashboard. As a client,  you’ll gain an insightful summary of your Finxact Core Banking metrics, along with an intuitive configuration journey that will allow you to quickly begin configuring your banking experience.",
    title: "Top Tooltip",
    placement: "top",
    target: "#top",
  },
  {
    content:
      "Welcome to your Finxact Core Banking! Let’s begin on the Dashboard. As a client,  you’ll gain an insightful summary of your Finxact Core Banking metrics, along with an intuitive configuration journey that will allow you to quickly begin configuring your banking experience.",
    title: "Right Tooltip",
    placement: "bottom",
    target: "#bottom",
  },
  {
    content:
      "Welcome to your Finxact Core Banking! Let’s begin on the Dashboard. As a client,  you’ll gain an insightful summary of your Finxact Core Banking metrics, along with an intuitive configuration journey that will allow you to quickly begin configuring your banking experience.",
    title: "Left Tooltip",
    placement: "left",
    target: "#left",
  },
];

//Onboarding Tour
const ONBOARDING_TOUR_GUIDED_TOOLTIP_STEPS = [
  {
    content:
      "Welcome to your Finxact experience. In this brief onboarding, we’ll give you a tour of the key features and pages to know that will help ground your Finxact experience. Let’s begin on the Dashboard. Here, we provide an intuitive journey tracker that guides you from platform setup to product deployment. You’ll also find announcements, release notes, and information about your environment. ",
    title: "Welcome to Finxact! Let’s show you around",
    placement: "top",
    target: "#icon",
    leftButton: "Skip",
    rightButton: "Start tour",
    disableBeacon: true,
  },
  {
    content:
      "Our powerful Universal Search scans across the Finxact platform to provide relevant documents and e-learnings based on your search input. For example, this “general ledger” search pulls up helpful information around related GL topics.",
    title: "Universal Search to help you find the resources you need",
    placement: "left",
    target: "#search",
    leftButton: "Back",
    rightButton: "Next",
    disableBeacon: true,
  },
  {
    content:
      "Academy opens the door to a world of learning with Finxact. Dive into e-learnings that will prepare you for your configuration journey, and work towards earning badges and certifications. Academy tracks your learning progress to make it easy to return anytime, and enables you to bookmark resources for later reference.",
    title: "Explore, learn, and earn with Academy",
    placement: "top",
    target: "#icon",
    leftButton: "Back",
    rightButton: "Next",
    disableBeacon: true,
  },
  {
    content:
      "One specific area of our learning resources to highlight is Documentation. We have a robust repository of documents that will support you throughout configuration by explaining concepts, surfacing step-by-step guidance, and answering open questions.",
    title: "Detailed documentation to support you every step of the way",
    placement: "top",
    target: "#icon",
    leftButton: "Back",
    rightButton: "Next",
    disableBeacon: true,
  },
  {
    content: `Last, but certainly not least - our API References surface the endpoints that power Console. API resources enable you to copy, download, and reference code efficiently to progress your configuration. Our clear and intuitive content display will help developers and business analysts alike.
      
      That’s it for the tour! We’re excited for you to begin your journey.`,
    title: "Robust API References to enable powerful configurations",
    placement: "top",
    target: "#icon",
    leftButton: "Back",
    rightButton: "Home",
    disableBeacon: true,
  },
];
const CHARGE_OFF_TRANSACTION_CODE: AppRadioProps<any>[] = [
  {
    label: "Select charge off transaction code field",
    value: "1",
  },
  {
    label: "Select charge off transaction code matrix",
    value: "2",
  },
];
export {
  LEARNING_INFO_CARD_PROPS_1,
  LEARNING_INFO_CARD_PROPS_2,
  LEARNING_INFO_CARD_PROPS_3,
  PRODUCT_INFO_CARD_PROPS_1,
  MODAL_DATA_1,
  MODAL_DATA_2,
  MODAL_DATA_3,
  CONTENT_CATALOGUE_1,
  MULTI_SELECT_PROP,
  MULTI_SELECT_PROP_LEDGERS,
  MULTI_SELECT_PROP_WITH_ARR_OBJ,
  RANGE_SLIDER_PROPS,
  PRODUCT_REVIEW,
  DOCUMENT_SLIDER,
  RADIO_DATA_LIST,
  CURRENCY_DATA,
  RADIO_FEE_TYPE_OPTION,
  NAME_OF_CYCLE,
  NUMBER_OF_DAYS,
  NON_BUSINESS_DAY,
  POSTING_ROUND_OPTION,
  DISBURSMENT_OPTION,
  GUIDED_TOOLTIP_STEPS,
  TEST_COMPONENT_GUIDED_TOOLTIP_STEPS,
  ONBOARDING_TOUR_GUIDED_TOOLTIP_STEPS,
  REMOVE_USER_RADIO_LIST,
  CHARGE_OFF_TRANSACTION_CODE,
};
