"use client";

import { PrismicRichText } from "@prismicio/react";
import { useState, useEffect } from "react";

import {
  UnorderedList,
  OrderedList,
  Link,
  Image,
  VStack,
  Divider,
} from "@/components/ChakraUiManager";

import "./CustomPrismicRichText.scss";
import { buildURL } from "@/prismic-api/common-service";
import { ReactElement } from "react";
import { Element } from "react-scroll";

import { getDefintion } from "@/prismic-api/prismic-api-service";
import FeatureTooltip from "@/components/common/tooltips/FeatureTooltip/FeatureTooltip";
import DefinitionTooltip from "@/components/common/tooltips/DefinitionTooltip/DefinitionTooltip";
import { useSelector } from "react-redux";
import { getAppPrismicClient } from "@/store";

type InDocLinkProps = {
  key: string;
  children: ReactElement;
  id: string;
  definition?: { text: string }[];
};

interface AcademyConfigDocumentData {
  definition?: { text: string }[]; // Ensure the definition property exists
  // other properties...
}

const InDocLink = ({ key, children, id }: InDocLinkProps) => {
  const scrollTo = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Link key={key} className="prismic-hyperlink" onClick={() => scrollTo(id)}>
      {children}
    </Link>
  );
};

interface AcademyConfigDocumentData {
  definition?: { text: string }[]; // Ensure the definition property exists
  // other properties...
}
// Define the type for the response from getByID
interface DefinitionResponse {
  data: AcademyConfigDocumentData;
}

const InDocLinkToolTip = ({ key, children, id }: InDocLinkProps) => {
  const prismicClient = useSelector(getAppPrismicClient);

  const [tooltipWrapperVisible, setTooltipWrapperVisible] = useState(false);
  const [definition, setDefinition] = useState("");

  useEffect(() => {
    const fetchDefinition = async () => {
      try {
        const definitionRaw = await getDefintion(prismicClient, id);
        if (definitionRaw !== null) {
          setDefinition(definitionRaw);
        } else {
          console.error("Incomplete definition data", definitionRaw);
        }
      } catch (error) {
        console.error("Error fetching definition:", error);
      }
    };

    fetchDefinition();
  }, [id]);

  const handleClick = () => {
    setTooltipWrapperVisible(!tooltipWrapperVisible);
  };

  return (
    <div style={{ position: "relative", display: "inline" }}>
      <Link
        key={key}
        id={id}
        className="prismic-hyperlink"
        onClick={handleClick}
      >
        {children}
      </Link>

      {tooltipWrapperVisible && (
        <>
          <div className="tooltipDef">
            {definition && (
              <DefinitionTooltip
                content={definition}
                placement={"top"}
                tooltipVisible={tooltipWrapperVisible}
                setTooltipWrapperVisible={setTooltipWrapperVisible}
              >
                {children}
              </DefinitionTooltip>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export const defaultComponents = {
  heading1: ({ node, children, key }: any) => <h1 key={key}>{children}</h1>,
  heading2: ({ node, children, key }: any) => (
    <h2 className="prismic-heading-two">{children}</h2>
  ),
  heading3: ({ node, children, key }: any) => (
    <h3 className="prismic-heading-three">{children}</h3>
  ),
  hyperlink: ({ node, children, key }: any) => {
    let url = node.data.url;

    if (node.data.link_type === "Document") {
      url = buildURL(node.data.type, node.data.uid);
    }

    return node.data.type === "definition" ? (
      // Third condition when node.data.id is present
      <InDocLinkToolTip id={node.data.id} key={key}>
        {children}
      </InDocLinkToolTip>
    ) : node.data.url?.startsWith("#") ? (
      // First condition when url starts with "#"
      <InDocLink id={`${node.text.replaceAll(" ", "-")}`} key={key}>
        {children}
      </InDocLink>
    ) : (
      // Second condition for all other urls
      <Link key={key} href={url} className="prismic-hyperlink">
        {children}
      </Link>
    );
  },
  list: ({ node, children, key }: any) => (
    <UnorderedList mt={6.4} mb={6.4} spacing={6.4} className="prismic-list">
      {" "}
      {children}
    </UnorderedList>
  ),
  oList: ({ node, children, key }: any) => (
    <OrderedList className="prismic-list">{children}</OrderedList>
  ),
  paragraph: ({ node, children, key }: any) => (
    <p className="prismic-paragraph">{children}</p>
  ),
  image: ({ node, children, key }: any) => {
    return (
      <VStack align="center">
        <Image
          className="prismic-image"
          src={node.url}
          alt={node.alt || "Screenshot image "}
        />
        <Link
          className="prismic-hyperlink  prismic-image-downloadable"
          href={node.url}
          hidden
        >
          {node.url}
        </Link>
      </VStack>
    );
  },
};

export const h2WithDivider = ({ node, children, key }: any) => {
  return (
    <Element
      name={`${node.text}`}
      className="slice-item"
      id={`${node.text.replaceAll(" ", "-")}`}
    >
      {node.text && (
        <Divider orientation="horizontal" className="prismic-divider" />
      )}
      <h2 className="prismic-heading-two">{children}</h2>
    </Element>
  );
};

const CustomPrismicRichText = ({
  field,
  components = defaultComponents,
}: any) => {
  return <PrismicRichText field={field} components={components} />;
};

export default CustomPrismicRichText;
